// @ts-check

import { useFsFlag } from '@flagship.io/react-sdk';
import { AB_TEST_FLAGS } from 'constants/feature-flags';
import { useSelector } from 'react-redux';

/**
 * This hook will return a boolean value to determine if a user should be navigated to carrier store after sign up
 * or to personalized recommendations page.
 * @returns {boolean} when true navigate to personalized recommendations page
 */
export function useShouldNavigateToPersonalizedRecommendations() {
  const flags = useSelector((state) => state.producerFlags);
  const personalizationEnabledFlag = useFsFlag(
    AB_TEST_FLAGS.PERSONALIZED_RECOMMENDATIONS_ENABLED
  );
  const isPersonalizedRecommendationEnabled =
    personalizationEnabledFlag.getValue(false);
  const isExperimentCompleted =
    flags.isPersonalizedRecommendationsFlowCompleted === true;

  return !isExperimentCompleted && isPersonalizedRecommendationEnabled;
}
