import * as type from '../../constants/action-types';

export const fetchProducerFlags = () => ({
  type: type.FETCH_PRODUCER_FLAGS,
});

/**
 * @param {boolean} isEnabled
 * @returns {{type: string, payload: boolean}}
 */
export const setIsReferralProgramEnabled = (isEnabled) => ({
  type: type.SET_IS_REFERRAL_PROGRAM_ENABLED,
  payload: isEnabled,
});

/**
 * @param {boolean} isFlowCompleted
 * @returns {{type: string, payload: boolean}}
 */
export const setIsPersonalizedRecommendationsFlowCompleted = (isEnabled) => ({
  type: type.SET_IS_PERSONALIZED_RECOMMENDATIONS_FLOW_COMPLETED,
  payload: isEnabled,
});
