import {
  Button,
  CardActions,
  CardContent,
  Input,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from '@material-ui/icons';
import { Helmet } from 'react-helmet';
import ProcessFooter from 'components/process-footer';
import ProcessHeader from 'components/process-header';
import SubmitLoader from 'components/submit-loader';
import {
  AGREEMENT,
  AS_ORGANIZATION_CODE,
  DM_ORGANIZATION_CODE,
  FC_ORGANIZATION_CODE,
  INCORRECT_EMAIL,
  PM_ORGANIZATION_CODE,
  REQUIRED,
  SIGNOUT_IS_SSO_ERROR,
  TYPE_PASSWORD,
  TYPE_TEXT,
} from 'constants/constant';
import { isEmpty } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import IntlMessages from 'util/intl-messages';
import getQueryParams from 'util/query-string';
import { email_regex } from 'util/sign-up-process';
import { displayErrorMessage } from 'util/user-feedback';
import ameriSaveLogo from '../assets/images/AmeriSave.svg';
import domaLogo from '../assets/images/doma/domaLogo.svg';
import domaHome from '../assets/images/doma/home-frame.svg';
import homeBg from '../assets/images/landing-pg-bg.svg';
import hand from '../assets/images/pennymac/hand.svg';
import addImage from '../assets/images/pennymac/new-home-loan.jpg';
import pennymacLogo from '../assets/images/pennymac/pennymac-loan-services-logo.svg';
import {
  checkIsPasswordless,
  hideMessage,
  setInitUrl,
  setIsGlobalAuthLoaderShown,
  signinAuth0HandleRedirect,
  updateSignInFormCustomStep,
  userSignIn,
} from '../redux/actions/auth';
import PasswordlessSignInForm from './passwordless-sign-in-form/passwordless-sign-in-form';
import { storeDefaultPasswordlessSignInputVal } from './passwordless-sign-in-form/passwordless-sign-in-form.utils';
import { GlobalAuthLoader } from 'components/global-loader';
import DancingDots from 'components/dancing-dots';
import { IS_POST_PASSWORDLESS_MIGRATION } from 'util/auth/auth0.constants';
import { hideRecaptchaBadge, showRecaptchaBadge } from 'util/recaptcha';
import { ORGANIZATION_DETAILS } from 'constants/organization-details';
import { isAuthenticated } from 'util/auth/is-authenticated';

// At this stage - components maintainability is low, however due to migration <https://myhippo.atlassian.net/browse/FRC-5353> we do not care enough to refactor.
class SignIn extends React.Component {
  constructor() {
    super();
    if (!window.heap) {
      window.location.reload();
    }
    this.state = {
      email: '',
      password: '',
      signInError: {
        emailError: '',
        passwordError: '',
      },
      url: '',
      showPassword: false,
      redirectedFromAuth0: false,
      hasUnauthorizedSnackbarBeenShown: false,
      isPasswordlessTeamMemberSignUp: false,
    };
  }

  // We are dangerously deep into an anti-pattern territory here. Ideally, move that logic to a saga.
  UNSAFE_componentWillMount() {
    const {
      userRole,
      history,
      authUser,
      dashboard,
      page,
      updateDefaultPassword,
      producerEmail,
    } = this.props;
    const { location } = this.props;
    const encodedEmail =
      getQueryParams(location.search, 'em') ??
      getQueryParams(location.search, 'email');
    if (!!encodedEmail) {
      this.setState({
        // Decode the query param value
        // E.g. example%40gmail.com -> example@gmail.com
        email: decodeURIComponent(encodedEmail),
      });
    }

    if (this.isContinueEnOUpload()) {
      localStorage.setItem('page', 'eno');
    }

    const isUserAuthenticated = isAuthenticated() && authUser !== null;
    // if already user logged in (browser back navigation).
    if (isUserAuthenticated && !dashboard) {
      // for post signup step process
      if (page === AGREEMENT) {
        history.push('/post-signup/agreement-acceptance');
      } else if (updateDefaultPassword) {
        history.push('/post-signup/update-new-password');
      } else {
        history.push('/post-signup');
      }
    } else if (isUserAuthenticated && dashboard && !!userRole) {
      if (updateDefaultPassword) {
        history.push('/post-signup/update-new-password');
      } else {
        history.push(`/app/${userRole}`);
      }
    }

    if (window.location.hash && producerEmail) {
      this.setState({
        redirectedFromAuth0: true,
        email: producerEmail,
        password: '********',
      });
    }

    if (this.isPasswordlessTeamMemberSignUp()) {
      this.setState({
        isPasswordlessTeamMemberSignUp: true,
      });
      this.props.checkIsPasswordless({
        email: decodeURIComponent(encodedEmail),
      });
    }

    // update initial custom step
    let initialCustomStep;

    if (IS_POST_PASSWORDLESS_MIGRATION) {
      // post migration we always want to show the passwordless
      initialCustomStep = 'passwordless';
    } else {
      // else passwordless (if indicated via params) or email standalone
      initialCustomStep = 'emailStandalone';

      const queryParams = new URLSearchParams(window.location.search);
      const fromParam = queryParams.get('from');

      if (['email', 'sms'].includes(fromParam)) {
        initialCustomStep = 'passwordless';
      }

      // Delete from param if exists
      if (fromParam) {
        queryParams.delete('from');
        window.history.replaceState(
          {},
          document.title,
          `${window.location.pathname}?${queryParams.toString()}`
        );
      }
    }

    this.props.updateSignInFormCustomStep(initialCustomStep);

    // If redirected from Auth0 back to sign in after auth, handle it
    this.props.signinAuth0HandleRedirect();
  }

  isContinueEnOUpload = () => {
    return getQueryParams(location.search, 'process') === 'eno';
  };

  isPasswordlessTeamMemberSignUp = () => {
    return getQueryParams(location.search, 'process') === 'team-member-sign-up';
  };

  componentDidMount() {
    if (window.location.search.includes(`${SIGNOUT_IS_SSO_ERROR}=true`)) {
      setTimeout(() => {
        displayErrorMessage('You need SSO to login as admin');
      }, 500);
    }

    // Show reCAPTCHA badge <https://myhippo.atlassian.net/browse/FRC-5951>
    if (window.location.pathname === '/signin') {
      showRecaptchaBadge();
    }
  }

  componentWillUnmount() {
    // Hide reCAPTCHA badge <https://myhippo.atlassian.net/browse/FRC-5951>
    hideRecaptchaBadge();
  }

  // We are dangerously deep into an anti-pattern territory here. Ideally, move that logic to a saga.
  componentDidUpdate(prevProps) {
    const {
      userRole,
      history,
      authUser,
      dashboard,
      updateDefaultPassword,
      producer_code,
    } = this.props;

    if (
      this.state.redirectedFromAuth0 &&
      this.props.loader !== prevProps.loader
    ) {
      if (this.props.loader === false) {
        this.setState({
          redirectedFromAuth0: false,
          password: '',
        });
      }
    }

    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      });
    }

    if (
      getQueryParams(location.search, 'unauthorized') === 'true' &&
      !this.state.hasUnauthorizedSnackbarBeenShown
    ) {
      this.setState((prev) => ({
        ...prev,
        hasUnauthorizedSnackbarBeenShown: true,
      }));
      displayErrorMessage('Your session has expired. Please sign in again.');
    }
    const isUserAuthenticated = isAuthenticated() && authUser !== null;
    if (isUserAuthenticated) {
      // previous code was written as if producer_code could be optional value
      // verify if issue is logged - if not, remove the if condition
      if (producer_code) {
        window.heap?.identify(producer_code);
      } else {
        // eslint-disable-next-line no-console
        console.error(
          '[HEAP TRACKING] Producer code not found. Cannot identify user.'
        );
      }

      if (!dashboard) {
        if (this.props.page === AGREEMENT) {
          this.props.history.push('/post-signup/agreement-acceptance');
        } else if (updateDefaultPassword) {
          history.push('/post-signup/update-new-password');
        } else {
          this.props.history.push('/post-signup');
        }
      } else {
        if (updateDefaultPassword) {
          history.push('/post-signup/update-new-password');
        } else {
          history.push(`/app/${userRole}`);
        }
      }
    }
  }

  onSignInSubmit = (event) => {
    event.preventDefault();
    const { email, password } = this.state;
    const step = this.getCustomStep();

    if (step === 'emailStandalone') {
      if (this.validate({ validateOnlyEmail: true })) {
        storeDefaultPasswordlessSignInputVal(email, 'email');
        // Get info whether to display passwordless or password flow for the user
        this.props.checkIsPasswordless({ email });
      }
      return;
    }

    if (this.validate()) {
      this.props.userSignIn({
        email,
        password,
      });
    }
  };

  validate = ({ validateOnlyEmail } = { validateOnlyEmail: false }) => {
    const { email, password } = this.state;
    let signInError = { ...this.state.signInError };
    signInError = {
      emailError: '',
      passwordError: '',
    };
    if (!email.length > 0) {
      signInError.emailError = REQUIRED;
    }
    if (email.length > 0 && !email_regex.test(email)) {
      signInError.emailError = INCORRECT_EMAIL;
    }
    if (!validateOnlyEmail) {
      if (!password.length > 0) {
        signInError.passwordError = REQUIRED;
      }
    }
    this.setState({
      signInError,
    });
    if (signInError.emailError || signInError.passwordError) {
      return false;
    }
    return true;
  };

  handleClickShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({
      showPassword: !showPassword,
    });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  redirectToSignUp = () => {
    this.props.history.push('/post-signup');
  };

  /**
   * Returns custom step for passwordless sign in.
   * @returns {'emailStandalone' | 'loading' | 'passwordless' | null}
   */
  getCustomStep = () => {
    const { signInFormCustomStep } = this.props;
    return signInFormCustomStep ?? 'emailStandalone';
  };

  render() {
    const customStep = this.getCustomStep();

    const isLegacyPasswordForm = customStep === null;

    const isStepLoading = customStep === 'loading';
    const isStandaloneEmail = customStep === 'emailStandalone';
    const isPasswordlessForm = customStep === 'passwordless';

    const isEmailInputDisplayed = isStandaloneEmail && !isStepLoading;

    const { email, password, signInError, showPassword, redirectedFromAuth0 } =
      this.state;
    const {
      loader,
      alertMessage,
      showMessage,
      orgDetails,
      orgDetailsLoader,
      isTextSigningAvailable,
      isVerified,
    } = this.props;
    const defaultOrganization = ORGANIZATION_DETAILS.organizationCode;
    const isEnabled = !isEmpty(email) && !isStepLoading;

    // Avoids part of layout shift - for now just passwordless. Peoper layout shift fix needed in the whole app.
    if (
      (this.props.loader && !isPasswordlessForm) ||
      this.props.isGlobalAuthLoaderShown
    ) {
      return <GlobalAuthLoader />;
    }

    const oldSignInFormJSX = (
      <form
        className="form-width"
        onSubmit={this.onSignInSubmit}
        style={{ minWidth: '380px' }}
      >
        <div className="form-card">
          <CardContent className="form-card-content">
            <div className="mx-auto">
              <div className="heading">
                <Typography className="login-label">
                  <IntlMessages id="label.login" />
                </Typography>
              </div>
              <div className="row">
                <div
                  className="input-field mb-40 col-sm-12 col-md-12 col-lg-12"
                  style={{
                    marginBottom: isStepLoading ? '28px' : undefined,
                  }}
                >
                  {isEmailInputDisplayed && (
                    <>
                      <input
                        id="email"
                        value={email}
                        type="text"
                        className="fs-exclude validate"
                        maxLength="50"
                        onChange={(event) =>
                          this.setState({
                            email: event.target.value,
                          })
                        }
                        onBlur={(event) =>
                          this.setState({
                            email: event.target.value.trim(),
                          })
                        }
                        disabled={loader}
                      />
                      <label htmlFor="email" className="active">
                        <IntlMessages id="label.email" />
                        <span className="required">*</span>
                      </label>
                      <div className="fieldError">{signInError.emailError}</div>
                    </>
                  )}
                  {isStepLoading && (
                    <div
                      style={{
                        backgroundColor: '#F7F9FA',
                        width: '300px',
                        height: '54px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                      }}
                    >
                      <DancingDots />
                    </div>
                  )}
                </div>
                {isLegacyPasswordForm && (
                  <>
                    <div className="input-field mb-24 col-sm-12 col-md-12 col-lg-12 password-login">
                      <Input
                        fullWidth
                        id="password"
                        type={showPassword ? TYPE_TEXT : TYPE_PASSWORD}
                        value={password}
                        maxLength="50"
                        className="fs-exclude validate"
                        onChange={(event) =>
                          this.setState({
                            password: event.target.value,
                          })
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              onMouseDown={this.handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOffOutlinedIcon />
                              ) : (
                                <VisibilityOutlinedIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        disabled={loader}
                      />
                      <label htmlFor="password" className="active">
                        <IntlMessages id="appModule.password" />
                        <span className="required">*</span>
                      </label>
                      <div className="fieldError">
                        {signInError.passwordError}
                      </div>
                    </div>
                  </>
                )}

                {isLegacyPasswordForm && (
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <NavLink to="/forgotpassword" className="forgotLink">
                      <span className="nav-text font-weight-bold">
                        <IntlMessages id="label.forgot.password" />
                      </span>
                    </NavLink>
                  </div>
                )}
              </div>
            </div>
          </CardContent>
          <CardActions className="p-0 login-button-wrap">
            <Typography className="signup-text-label">
              Not on First Connect?
              <span className="resp-d-block">
                <span
                  className="text-orange padding-left-1"
                  onClick={this.redirectToSignUp}
                >
                  Sign up
                </span>{' '}
                to start your application.
              </span>
            </Typography>
            <button
              className={`${!isEnabled ? 'disable' : ''} login-btn`}
              disabled={loader || !isEnabled}
            >
              <IntlMessages id="label.login" />
              {loader && <SubmitLoader className="ml-1" />}
            </button>
          </CardActions>
        </div>
      </form>
    );

    return (
      <div className="app-container login-container">
        <Helmet defaultTitle="First Connect">
          <title>Log in to First Connect</title>
          <meta
            name="description"
            content="Log in to the First Connect Portal to access more than 100 carriers, and the technology you need to grow your agency."
          />
          <meta
            name="keywords"
            content="log in, login, sign in, start, agent portal"
          />
        </Helmet>
        <div className="app-main-container">
          {!orgDetailsLoader ? (
            (!!orgDetails?.organizationCode &&
              (orgDetails?.organizationCode === PM_ORGANIZATION_CODE ||
                orgDetails?.organizationCode === DM_ORGANIZATION_CODE ||
                orgDetails?.organizationCode === AS_ORGANIZATION_CODE)) ||
            defaultOrganization === PM_ORGANIZATION_CODE ||
            defaultOrganization === DM_ORGANIZATION_CODE ||
            defaultOrganization === AS_ORGANIZATION_CODE ? (
              <>
                <div
                  className={`landing-page container-fluid bg-white h-100 ${
                    orgDetails?.organizationCode === AS_ORGANIZATION_CODE ||
                    defaultOrganization === AS_ORGANIZATION_CODE
                      ? 'wirefreame-bg-green'
                      : 'wirefreame-bg'
                  }`}
                >
                  <div
                    className={`${
                      orgDetails?.organizationCode === AS_ORGANIZATION_CODE ||
                      defaultOrganization === AS_ORGANIZATION_CODE
                        ? 'green-logo-wrap'
                        : 'logo-wrap'
                    }`}
                  >
                    <img
                      src={
                        (!!orgDetails?.organizationCode &&
                          orgDetails.organizationCode ===
                            PM_ORGANIZATION_CODE) ||
                        defaultOrganization === PM_ORGANIZATION_CODE
                          ? pennymacLogo
                          : orgDetails?.organizationCode ===
                              AS_ORGANIZATION_CODE ||
                            defaultOrganization === AS_ORGANIZATION_CODE
                          ? ameriSaveLogo
                          : domaLogo
                      }
                      alt={
                        !!orgDetails?.organizationCode &&
                        orgDetails?.organizationCode === DM_ORGANIZATION_CODE
                          ? 'Doma'
                          : orgDetails?.organizationCode ===
                            AS_ORGANIZATION_CODE
                          ? 'AmeriSave'
                          : orgDetails?.organizationCode !==
                            FC_ORGANIZATION_CODE
                          ? 'PennyMac'
                          : 'Agency Connector'
                      }
                      className={`img-fluid ${
                        orgDetails?.organizationCode === AS_ORGANIZATION_CODE ||
                        defaultOrganization === AS_ORGANIZATION_CODE
                          ? 'AS-logo'
                          : 'logo-img'
                      }`}
                    />
                  </div>
                  <div className="row h-100 align-items-start d-flex">
                    <div className="col-lg-6">
                      <div
                        className={`row ${
                          orgDetails?.organizationCode ===
                            AS_ORGANIZATION_CODE ||
                          defaultOrganization === AS_ORGANIZATION_CODE
                            ? 'margin-top-15'
                            : 'margin-top-7'
                        }`}
                      >
                        <div className="col-lg-12 d-flex justify-content-end">
                          <div className="row d-flex justify-content-center">
                            <div className="col-lg-10">
                              <Typography className="login-heading">
                                <IntlMessages id="label.log.in" />
                                <img src={hand} alt="hand" />
                              </Typography>

                              <form
                                noValidate
                                autoComplete="off"
                                className={`text-field ${
                                  orgDetails?.organizationCode ===
                                    AS_ORGANIZATION_CODE ||
                                  defaultOrganization === AS_ORGANIZATION_CODE
                                    ? 'AS-form-wrap'
                                    : ''
                                }`}
                                onKeyPress={(event) => {
                                  if (event.key === 'Enter') {
                                    this.onSignInSubmit(event);
                                  }
                                }}
                              >
                                <TextField
                                  fullWidth
                                  placeholder="email"
                                  variant="outlined"
                                  onChange={(event) =>
                                    this.setState({
                                      email: event.target.value,
                                    })
                                  }
                                  onBlur={(event) =>
                                    this.setState({
                                      email: event.target.value.trim(),
                                    })
                                  }
                                  value={email}
                                  disabled={loader}
                                  error={
                                    !!signInError?.emailError ? true : false
                                  }
                                  helperText={
                                    !!signInError?.emailError
                                      ? signInError.emailError
                                      : ''
                                  }
                                  inputProps={{ maxLength: 50 }}
                                />
                                <TextField
                                  fullWidth
                                  placeholder="password"
                                  variant="outlined"
                                  type="password"
                                  onChange={(event) =>
                                    this.setState({
                                      password: event.target.value,
                                    })
                                  }
                                  error={
                                    !!signInError?.passwordError ? true : false
                                  }
                                  helperText={
                                    !!signInError?.passwordError
                                      ? signInError.passwordError
                                      : ''
                                  }
                                  value={password}
                                  inputProps={{ maxLength: 50 }}
                                  disabled={loader}
                                />
                                <div className="forget-pwd-div">
                                  <NavLink
                                    to="/forgotpassword"
                                    className="forgotLink"
                                  >
                                    <span className="nav-text font-weight-bold">
                                      <IntlMessages id="label.forgot.password" />
                                    </span>
                                  </NavLink>
                                </div>
                              </form>
                              <div className="d-flex justify-content-end">
                                <Button
                                  variant="contained"
                                  className="button-login text-center"
                                  onClick={this.onSignInSubmit}
                                  disabled={!isEnabled}
                                >
                                  <IntlMessages id="label.login" />
                                  {loader && <SubmitLoader className="ml-1" />}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 d-flex align-items-center">
                      <img
                        src={
                          (!!orgDetails?.organizationCode &&
                            orgDetails.organizationCode ===
                              PM_ORGANIZATION_CODE) ||
                          defaultOrganization === PM_ORGANIZATION_CODE
                            ? addImage
                            : orgDetails?.organizationCode ===
                                AS_ORGANIZATION_CODE ||
                              defaultOrganization === AS_ORGANIZATION_CODE
                            ? homeBg
                            : domaHome
                        }
                        alt="img"
                        className={`img-fluid ${
                          (!!orgDetails?.organizationCode &&
                            orgDetails.organizationCode ===
                              PM_ORGANIZATION_CODE) ||
                          defaultOrganization === PM_ORGANIZATION_CODE
                            ? 'frame-img'
                            : orgDetails?.organizationCode ===
                                AS_ORGANIZATION_CODE ||
                              defaultOrganization === AS_ORGANIZATION_CODE
                            ? 'green-home-img'
                            : 'doma-home-img'
                        }`}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="post-signup-wrapper">
                  <div className={`app-header`}>
                    <ProcessHeader />
                  </div>
                  <main className="app-main-content-wrapper">
                    <div
                      className="app-main-content"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        minHeight: '100vh',
                      }}
                    >
                      <div
                        className="section no-pad-bot blue-grey lighten-5 d-flex align-items-center"
                        id="sign-up-form-field"
                        style={{
                          minHeight: 'unset',
                          marginTop: '158px',
                        }}
                      >
                        <div className="container">
                          <div
                            className={
                              'signin-form d-flex justify-content-center' +
                              (redirectedFromAuth0
                                ? ''
                                : ' animated slideInUpTiny animation-delay-1')
                            }
                          >
                            {isPasswordlessForm && (
                              <PasswordlessSignInForm
                                disableTextFlow={
                                  this.state.isPasswordlessTeamMemberSignUp ||
                                  !isTextSigningAvailable ||
                                  !isVerified
                                }
                                prefilledEmail={this.state.email}
                                redirectToSignUp={this.redirectToSignUp}
                              />
                            )}
                            {!isPasswordlessForm && oldSignInFormJSX}
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
                <ProcessFooter />
              </>
            )
          ) : (
            <GlobalAuthLoader />
          )}
          {showMessage && displayErrorMessage(alertMessage)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, organizationDetails, signIn }) => {
  const {
    userRole,
    authUser,
    loader,
    alertMessage,
    showMessage,
    dashboard,
    page,
    updateDefaultPassword,
    producer_code,
    fkParentProducerId,
    role,
    hasQuotingEngineAccess,
    signInFormCustomStep,
    isGlobalAuthLoaderShown,
    producerEmail,
  } = auth;
  const { orgDetails, orgDetailsLoader } = organizationDetails;
  const { isTextSigningAvailable, isVerified } = signIn;
  return {
    userRole,
    authUser,
    loader,
    alertMessage,
    showMessage,
    dashboard,
    page,
    updateDefaultPassword,
    orgDetails,
    orgDetailsLoader,
    producer_code,
    fkParentProducerId,
    role,
    hasQuotingEngineAccess,
    signInFormCustomStep,
    isGlobalAuthLoaderShown,
    isTextSigningAvailable,
    isVerified,
    producerEmail,
  };
};

export default connect(mapStateToProps, {
  hideMessage,
  setInitUrl,
  userSignIn,
  signinAuth0HandleRedirect,
  checkIsPasswordless,
  updateSignInFormCustomStep,
  setIsGlobalAuthLoaderShown,
})(SignIn);
