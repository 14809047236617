import React from 'react';
import { TRAILING_SLASH_URL } from 'constants/constant';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import asyncComponent from 'util/async-component';
import { getDefaultPath } from 'util/extra';
import DancingDots from 'components/dancing-dots';

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const {
      match,
      modulePermissionsData,
      loader,
      location: { pathname },
      isNewCarrierStoreEnabled,
    } = this.props;
    let getPath =
      !!modulePermissionsData &&
      getDefaultPath({ module: modulePermissionsData, match, isNewCarrierStoreEnabled });
    let isImpersonated = localStorage.getItem('isImpersonatedDataPresent');

    const isAwaitingPermissions = !modulePermissionsData || loader;
    return isAwaitingPermissions ? (
      <DancingDots fullpageWrapper />
    ) : (
      <React.Fragment>
        <Switch>
          <Redirect from={TRAILING_SLASH_URL} to={pathname.slice(0, -1)} />
          <Redirect
            exact
            from={`${match.url}`}
            to={!!getPath ? getPath : `${match.url}/error`}
          />
          <Route
            path={`${match.url}/agent-directory`}
            component={asyncComponent(() => import('./routes/agent-directory'))}
          />
          <Route
            exact
            path={`${match.url}/commissions`}
            component={asyncComponent(() => import('./routes/commissions'))}
          />
          <Route
            path={`${match.url}/carriers`}
            component={asyncComponent(() => import('./routes/carriers'))}
          />
          <Route
            exact
            path={`${match.url}/adhoc-payments`}
            component={asyncComponent(() => import('./routes/adhoc-payments'))}
          />
          <Route
            exact
            path={`${match.url}/reports`}
            component={asyncComponent(() => import('./routes/reports'))}
          />
          <Route
            exact
            path={`${match.url}/monthly-commission-report`}
            component={asyncComponent(
              () => import('./routes/monthly-commission-report')
            )}
          />
          <Route
            exact
            path={`${match.url}/agent-on-boarding`}
            component={asyncComponent(
              () => import('./routes/agent-on-boarding')
            )}
          />
          <Route
            exact
            path={`${match.url}/agent-sync-report`}
            component={asyncComponent(
              () => import('./routes/agent-sync-report')
            )}
          />
          <Route
            exact
            path={`${match.url}/quickbooks-extract`}
            component={asyncComponent(
              () => import('./routes/quickbooks-extract')
            )}
          />
          <Route
            exact
            path={`${match.url}/fast-start-report`}
            component={asyncComponent(
              () => import('./routes/fast-start-report')
            )}
          />
          <Route
            exact
            path={`${match.url}/incentive-report`}
            component={asyncComponent(
              () => import('./routes/incentive-report')
            )}
          />
          <Route
            exact
            path={`${match.url}/appointment-failure-report`}
            component={asyncComponent(
              () => import('./routes/appointment-failure-report')
            )}
          />
          <Route
            exact
            path={`${match.url}/carrier-lob-markets-report`}
            component={asyncComponent(() =>
              import('./routes/carrier-lob-markets-report')
            )}
            />
            <Route
            exact
            path={`${match.url}/rewards-program-report`}
            component={asyncComponent(
              () => import('./routes/rewards-program-report')
            )}
          />
          <Route
            exact
            path={`${match.url}/motion-auto-s3-bucket`}
            component={asyncComponent(
              () => import('./routes/motion-auto-s3-bucket')
            )}
          />
          <Redirect
            exact
            from={`${match.url}/permission`}
            to={`${match.url}/permission/roles`}
          />
          <Route
            path={`${match.url}/permission/roles`}
            component={asyncComponent(() => import('./routes/permission/role'))}
          />
          <Redirect
            exact
            from={`${match.url}/portal-setting`}
            to={`${match.url}/portal-setting/product`}
          />
          <Route
            exact
            path={`${match.url}/portal-setting/product`}
            component={asyncComponent(
              () => import('components/product-list-table')
            )}
          />
          <Route
            exact
            path={`${match.url}/portal-setting/admin-users`}
            component={asyncComponent(
              () => import('components/admin-users-table')
            )}
          />
          <Route
            exact
            path={`${match.url}/portal-setting/incentive-banners`}
            component={asyncComponent(
              () => import('components/incentive-banner')
            )}
          />
          <Route
            exact
            path={`${match.url}/portal-setting/bulk-appointments`}
            component={asyncComponent(
              () => import('components/bulk-appointments')
            )}
          />
          <Route
            exact
            path={`${match.url}/portal-setting/agent-messaging`}
            component={asyncComponent(
              () => import('components/agent-messaging')
            )}
            />
            <Route
            exact
            path={`${match.url}/portal-setting/carriers-incentive`}
            component={asyncComponent(
              () => import('components/carrier-incentives')
            )}
          />
          <Route
            exact
            path={`${match.url}/portal-setting/loa-filters`}
            component={asyncComponent(() => import('./routes/loa-filters'))}
          />
          {isImpersonated && (
            <Route
              exact
              path={`${match.url}/new-quote`}
              component={asyncComponent(() => import('components/new-quote'))}
            />
          )}
          <Route
            exact
            path={`${match.url}/workers-comp`}
            component={asyncComponent(
              () => import('components/workers-comp/apetite')
            )}
          />
          <Route
            component={asyncComponent(() => import('components/error-404'))}
          />
        </Switch>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ auth, modulePermissions, producerSettings }) => {
  const { authUser, loader } = auth;
  const { modulePermissionsData } = modulePermissions;
  const { isNewCarrierStoreEnabled } = producerSettings;
  return { authUser, modulePermissionsData, loader, isNewCarrierStoreEnabled };
};
export default withRouter(connect(mapStateToProps, {})(Admin));
