// @ts-check

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { GlobalAuthLoader } from 'components/global-loader';
import ProcessFooter from 'components/process-footer';
import ProcessHeader from 'components/process-header';
import {
  SIGNOUT_EMAIL_SEARCH_PARAM,
  SIGNOUT_IS_EMAIL_VERIFY_SEARCH_PARAM,
  SIGNOUT_IS_INACTIVITY_REAUTHENTICATE_SEARCH_PARAM,
  SIGNOUT_IS_PASSWORDLESS_SEARCH_PARAM,
  SIGNOUT_IS_STEPUP_REAUTHENTICATE_SEARCH_PARAM,
} from 'constants/constant';
import { PORTAL } from 'constants/heap-events';
import PasswordlessSignInForm from 'containers/passwordless-sign-in-form/passwordless-sign-in-form';
import SignInInactivityReauthenticateBanner from 'containers/sign-in-inactivity-reauthenticate-banner';
import SignInStepUpReauthenticateBanner from 'containers/sign-in-stepup-reauthenticate-banner';
import { issueAuth0SpaPasswordLogin } from 'util/auth/auth0-password-flow-spa.utils';
import { issueAuth0SpaPasswordlessLoginEmail } from 'util/auth/auth0-passwordless-flow.utils';
import {
  AUTH_FLOW_PASSWORDLESS_ENABLED,
  IS_POST_PASSWORDLESS_MIGRATION,
} from 'util/auth/auth0.constants';
import axios from 'axios';
import config from 'config';
import { SIGNIN_ROUTE } from 'constants/routes';
import { isAuthenticated } from 'util/auth/is-authenticated';

// Route responsible for initiating user sign out.
// Sign out handled via a route due to dual app setup.
export default function ReauthenticatePage() {
  const [isStepUp, setIsStepUp] = useState(false);
  const history = useHistory();
  const [isPasswordlessFormShown, setIsPasswordlessFormShown] = useState(false);
  const [defaultEmail, setDefaultEmail] = useState('');

  const signInData = useSelector((state) => state.signIn);

  useEffect(function handlePostSignoutRedirect() {
    async function handlePostSignoutRedirectAsync() {
      const isUserAuthenticated = await isAuthenticated();

      if (isUserAuthenticated) {
        history.push(SIGNIN_ROUTE);
        return;
      }

      const params = new URLSearchParams(window.location.search);
      const email = params.get(SIGNOUT_EMAIL_SEARCH_PARAM);
      const isStepUpReauth =
        params.get(SIGNOUT_IS_STEPUP_REAUTHENTICATE_SEARCH_PARAM) === 'true';
      const isReauthenticating =
        params.get(SIGNOUT_IS_INACTIVITY_REAUTHENTICATE_SEARCH_PARAM) ===
        'true';
      const isPasswordless =
        params.get(SIGNOUT_IS_PASSWORDLESS_SEARCH_PARAM) === 'true';
      const isEmailVerify =
        params.get(SIGNOUT_IS_EMAIL_VERIFY_SEARCH_PARAM) === 'true';

      if (email) setDefaultEmail(email);
      if (isStepUpReauth) setIsStepUp(true);

      if (isEmailVerify) {
        try {
          const isInPasswordlessCohort = await fetchIsInPasswordlessCohort();

          // For email verification flow, redirect straight to Universal Login template
          if (isInPasswordlessCohort) {
            localStorage.setItem(AUTH_FLOW_PASSWORDLESS_ENABLED, 'true');
            await redirectToPasswordlessTemplate();
          } else {
            await redirectToPasswordTemplate();
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching producer cohort', error);
          // @todo: change to passwordless once majority of users are in passwordless cohort
          await redirectToPasswordTemplate();
          return;
        }

        return;
      }

      if (isPasswordless) {
        // For passwordless flows, show method selection form
        localStorage.setItem(AUTH_FLOW_PASSWORDLESS_ENABLED, 'true');
        setIsPasswordlessFormShown(true);
        return;
      }

      // Otherwise, for password flow, redirect straight to Universal Login
      await redirectToPasswordTemplate();
      return;

      async function redirectToPasswordlessTemplate() {
        await issueAuth0SpaPasswordlessLoginEmail({
          email: email || '',
          producerCode: signInData.unsafeProducerCode,
        });
      }

      async function redirectToPasswordTemplate() {
        await issueAuth0SpaPasswordLogin({
          email: email || '',
          producerCode: signInData.unsafeProducerCode,
          isReauthenticateBannerShown: isReauthenticating,
        });
      }

      async function fetchIsInPasswordlessCohort() {
        const res = await axios.get(
          `${
            // @ts-expect-error
            config.BFF_URL
          }/api/v1/producer/auth0-cohort?email=${encodeURIComponent(
            email || ''
          )}`
        );
        const cohort = res.data?.cohort;

        return cohort === 'passwordless';
      }
    }

    handlePostSignoutRedirectAsync();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const isPasswordless = params.get('isPasswordless') === 'true';
    if (!IS_POST_PASSWORDLESS_MIGRATION) {
      window.heap.track(PORTAL.AUTH_FLOW_VIEW_LOGIN, {
        'Login type': isPasswordless ? 'pwdless' : 'pwd',
        'Auto logout': true,
      });
    }
  }, []);

  if (isPasswordlessFormShown) {
    // Duplicate HTML with Sign In
    // TODO: extract layout
    return (
      <div className="app-container login-container">
        <div className="app-main-container">
          <div className="post-signup-wrapper">
            <div className={`app-header`}>
              <ProcessHeader />
            </div>
            <main className="app-main-content-wrapper">
              <div
                className="app-main-content"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  minHeight: '100vh',
                }}
              >
                {isStepUp ? (
                  <SignInStepUpReauthenticateBanner />
                ) : (
                  <SignInInactivityReauthenticateBanner />
                )}
                <div
                  className="section no-pad-bot blue-grey lighten-5 d-flex align-items-center"
                  id="sign-up-form-field"
                  style={{ minHeight: 'unset' }}
                >
                  <div className="container">
                    <div
                      className={
                        'signin-form d-flex justify-content-center animated slideInUpTiny animation-delay-1'
                      }
                    >
                      <PasswordlessSignInForm
                        disableTextFlow={
                          !signInData.isTextSigningAvailable ||
                          !signInData.isVerified
                        }
                        prefilledEmail={defaultEmail}
                        isReauthenticate
                        redirectToSignUp={() => {
                          history.push('/post-signup');
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
          <ProcessFooter />
        </div>
      </div>
    );
  }

  return <GlobalAuthLoader />;
}
