/* eslint-disable max-len */
import moment from 'moment';
import React from 'react';
import S from 'string';
import {
  cloneDeep,
  differenceWith,
  filter,
  findIndex,
  forEach,
  get,
  isEmpty,
  isEqual,
  isMatch,
  map,
  startCase,
  sum,
  uniq,
  valuesIn,
} from 'lodash';
import Skeleton from '@material-ui/lab/Skeleton';
import { encryptUrlParams } from 'util/crypto';
import {
  ADHOC_PAYMENTS,
  ADMIN,
  ADMIN_USERS_MENU,
  AGENCY_COMMISSION,
  AGENCY_PROFILE_MENU,
  AGENT_DIRECTORY,
  AGENT_DIRECTORY_ACTIVE,
  AGENT_DIRECTORY_ALL,
  AGENT_DIRECTORY_DORMANT,
  AGENT_DIRECTORY_ERROR_AND_OMMISSION,
  AGENT_DIRECTORY_INCOMPLETE_SIGNUP,
  AGENT_DIRECTORY_NPN,
  AGENT_DIRECTORY_PENDING,
  AGENT_DIRECTORY_REGULATORY_ACTION,
  AGENT_DIRECTORY_RESTRICTED,
  AGENT_DIRECTORY_SUSPEND,
  AGENT_DIRECTORY_TERMINATED,
  AGENT_DIRECTORY_W9,
  AGENT_MESSAGING,
  AGENT_ONBOARDING_REPORTS,
  AGENT_PROFILE,
  AGENT_SYNC_REPORTS,
  ALL_STATES,
  ALTERNATE_SUB_PRODUCER,
  APPOINTMENT_FAILURE_REPORT,
  AUTHORIZATION,
  BANK_INFORMATION,
  BULK_APPOINTMENTS,
  CARRIER_LOB_MARKETS_REPORT,
  CARRIERS,
  COMMISSION_INCENTIVE_YTD,
  COMMISSIONS,
  DASHBOARD,
  DOCUMENTS,
  ENO_INFORMATION,
  FAST_START_REPORT,
  FILE_PATH_NULL_BYTE_CHECK,
  FIRM,
  HTML_EM,
  INCENTIVE_REPORT,
  INVALID_EMAIL_NOTIFICATION,
  INVALID_FILE_NAME,
  LICENSE,
  LOA_FILTERS,
  MAX_COMMISSION_PERCENTAGE_ALLOWED,
  MINIMUM_AVALIABLE_CARRIERS_COUNT_TO_LOCK_PORTAL_USAGE,
  MONTHLY_COMMISSION_REPORT,
  MOTION_AUTO_DOCUMENT,
  MY_ACCOUNT,
  MY_COMMISSION,
  MY_POLICIES,
  OBJECT,
  POLICY_BY_CARRIER,
  PORTAL_SETTINGS,
  PRINCIPAL_ACCESS_ROLE,
  PRODUCTION_ACCESS,
  PRODUCTS_MENU,
  QUICKBOOKS_EXTRACT,
  QUOTES,
  RENAME,
  REPORTS,
  REQUIRED,
  REWARDS_PROGRAM_REPORT,
  ROLES,
  STRING,
  SUB_AGENT,
  SUB_PRODUCER_KEY,
  TOTAL_POLICY_TILL_DATE,
  TOTAL_PREMIUM_TILL_DATE,
  UPCOMING_RENEWALS,
  W9_INFORMATION,
} from 'constants/constant';
import axios from 'util/api';
import {
  email_regex,
  filename_starting_with_dot,
  forbidden_filename_characters_regex,
  getStartCase,
} from 'util/sign-up-process';
import { isIOS, isMobileOnly } from 'react-device-detect';
import { displayErrorMessage } from 'util/user-feedback';
import { getIsNewSignUpFlowEnabled } from 'util/auth/get-is-new-sign-up-flow-enabled';

// Intl NumberFormat API Compact notation uses locale-specific symbols to represent large numbers.
// It is a more human-friendly alternative to scientific notation
export const MoneyCompactFormat = (symbol, value) => {
  if (value < 1e3) return symbol + value;
  if (value >= 1e3 && value < 1e6)
    return symbol + (value / 1e3).toFixed(1) + 'K';
  if (value >= 1e6 && value < 1e9)
    return symbol + (value / 1e6).toFixed(1) + 'M';
  if (value >= 1e9 && value < 1e12)
    return symbol + (value / 1e9).toFixed(1) + 'B';
  if (value >= 1e12) return symbol + (value / 1e12).toFixed(1) + 'T';
};

// DOB format
export const date_format = 'MM/DD/YYYY';
export const dateAndTimeFormat = 'MM/DD/YYYY hh:mm A';

export const decimalFormat = /^\d{1,2}\.\d{0,2}$/;
export const FixedFormat = /^\d{1,5}\.\d{0,3}$/;
export const isHyphen = (value) => {
  return /^([a-zA-Z0-9]*-[a-zA-Z0-9]*)+$/.test(value);
};

// Format date in MM/DD/YYYY
export const formatDate = (date) => {
  let validDate = moment(date).format('MM/DD/YYYY');
  if (!moment(validDate, date_format, true).isValid()) {
    validDate = '-';
  }
  return validDate;
};

// Format date in MM/DD/YYYY
export const formatDateAndTime = (date) => {
  let validDate = moment(date).format(dateAndTimeFormat);
  if (!moment(validDate, dateAndTimeFormat, true).isValid()) {
    return '-';
  }
  return validDate;
};

/**
 * This function returns the time, spend from the date which is passed in parameter.
 * @param Date
 * @return String, the date calculated.
 */
export const getDateFromNow = (date) => {
  if (moment(date, date_format, true).isValid()) {
    return moment(date).fromNow();
  }

  return '-';
};

export const getCardExpireDate = (date) => {
  let expireDate = date.split('/');
  var startDate = moment([expireDate[1], expireDate[0] - 1]);
  var endDate = moment(startDate).endOf('month');
  return moment(endDate.toDate()).format('YYYY-MM-DD');
};

// Format Size in KB
export const formatSize = (bytes) => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

// convert any number to doller format
export const formatNumber = (
  amount,
  decimalCount = 2,
  decimal = '.',
  thousands = ','
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (negativeSign !== '-' ? '$' : '($') +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '') +
      (negativeSign !== '-' ? '' : ')')
    );
  } catch (e) {
    // error
  }
};

// format number to U.S. phone number (allows the optional +1 international code)
export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

export const normalizePhoneNumber = (phoneNumber) =>
  phoneNumber && phoneNumber.replace(/[^\d]/g, '');

export const normalizePercentageNumber = (percentValue) =>
  percentValue && Number(percentValue.replace(/[^\d]/g, ''));

// Sort by string value
export const stringSort = (a, b) => {
  a = a.replace(' ', '');
  b = b.replace(' ', '');
  return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
};

// Sort Numerical value
export const numberSort = (a, b) => {
  a = a.replace(' ', '');
  b = b.replace(' ', '');
  return a - b;
};

// Sort producer code
export const producerCode = (a, b) => {
  if (!a || !b) {
    return 0;
  }
  a = a.replace('-', '').slice(7);
  b = b.replace('-', '').slice(7);
  return (
    a.match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g)[0] -
    b.match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g)[0]
  );
};

// Sort $ amount excluding $ and convert number
export const amountSort = (a, b) => {
  a = a.replace(',', '');
  b = b.replace(',', '');
  return (
    Number(a.match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g)[0]) -
    Number(b.match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g)[0])
  );
};
// Convert phone number to display format (XXX) XXX-XXXX
export const convertPhoneNumberToDisplayFormat = (phone) => {
  if (isEmpty(phone)) return '-';
  return (
    '(' + phone.slice(0, 3) + ') ' + phone.slice(3, 6) + '-' + phone.slice(6)
  );
};

// Check is there any changes to license data.
export const compareDataChanges = (newData, oldData) => {
  let a = Object.assign({}, newData);
  let b = Object.assign({}, oldData);
  delete a.tableData;
  delete b.tableData;
  if (isEqual(a, b)) {
    return true;
  }
  return false;
};

// Sort by string month with year value
export const monthSort = (a, b) =>
  +moment(a, 'MMMM YYYY') - moment(b, 'MMMM YYYY');

// Formatted currency value sort
export const PercentValueSort = (a, b) =>
  normalizePercentageNumber(a) < normalizePercentageNumber(b) ? -1 : 1;

// Account type option
export const accountOptions = [
  {
    value: 'Select',
    label: '',
  },
  {
    label: 'Personal Checking',
    value: 'Personal Checking',
  },
  {
    value: 'Personal Saving',
    label: 'Personal Saving',
  },
  {
    value: 'Business Checking',
    label: 'Business Checking',
  },
  {
    value: 'Business Saving',
    label: 'Business Saving',
  },
];

// Tax status option
// Warning: Be cautious when adjusting any of these values. Some of the
// strings from statusOption values are used in the codebase for various conditions.
export const statusOption = [
  {
    value: 'Select',
    label: 'Select',
  },
  {
    value: 'Individual',
    label: 'Individual/Sole Proprietor',
  },
  {
    value: 'Single Member LLC',
    label: 'Single Member LLC',
  },
  {
    value: 'Partnership',
    label: 'Partnership',
  },
  {
    value: 'Limited Liability Co, (LLC) C corp',
    label: 'Limited Liability Co, (LLC) C corp',
  },
  {
    value: 'Limited Liability Co, (LLC) S corp',
    label: 'Limited Liability Co, (LLC) S corp',
  },
  {
    value: 'Limited Liability Co, (LLC) Partnership',
    label: 'Limited Liability Co, (LLC) Partnership',
  },
  {
    value: 'Corporation',
    label: 'Corporation',
  },
  {
    value: 'S Corporation',
    label: 'S Corporation',
  },
  {
    value: 'Trust/estate',
    label: 'Trust/estate',
  },
];

// Sub sgent status
export const subAgentStatus = [
  {
    value: 'Select',
    label: 'Select',
  },
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Inactive',
    value: 'Inactive',
  },
  {
    value: 'Restricted',
    label: 'Restrict',
  },
];

// Sub sgent status
export const agentLicensesStatus = [
  {
    value: 'Select',
    label: '',
  },
  {
    label: 'Active',
    value: 'Active',
  },
  {
    value: 'Pending',
    label: 'Pending',
  },
  {
    value: 'Inactive',
    label: 'Inactive',
  },
  {
    value: 'Cancelled',
    label: 'Cancelled',
  },
];

// graph month with label values
export const monthNameMappings = {
  April: 'Apr',
  May: 'May',
  June: 'Jun',
  July: 'Jul',
  August: 'Aug',
  September: 'Sep',
  October: 'Oct',
  November: 'Nov',
  December: 'Dec',
  January: 'Jan',
  February: 'Feb',
  March: 'Mar',
};

// Agent Status List
export const agentStatusList = {
  all: 'all',
  active: 'active',
  pending: 'pending',
  incomplete: 'incomplete',
  incompliance: 'incompliance',
  inactive: 'in active',
  cancel: 'cancel',
  restricted: 'restricted',
  suspend: 'suspend',
  dormant: 'dormant',
  terminated: 'terminated',
};

export const statusList = [
  {
    value: 'Active',
    label: 'Active',
  },
  {
    value: 'Pending',
    label: 'Pending',
  },
];

export const globalMessages = {
  invalidUploadFile:
    'Invalid file selected, valid files are of __PLACEHOLDER__ types.',
  maxEmailLengthError: 'Max length reached.',
  invalidEmailError: 'Invalid',
  returnMessage: 'Return to login',
  sendMessages: 'Send Request',
  passwordChangeEmailSentMessage:
    'A password reset message was sent to your email address. Please click the link in that message to reset your password.',
  changePasswordMessage: 'Password changed successfully. Please re-login.',
  fileUploadSuccessMessage: 'File uploaded successfully.',
  fileDeleteSuccessMessage: 'File deleted successfully.',
  folderCreateSuccessMessage: 'Folder created successfully.',
  fileRenameSuccessMessage: 'File renamed successfully.',
  incompleteSignupLoaderMessage:
    'Please wait while we are checking submitted data.',
  // E&O/W9/Licence message
  licenseUpdateMsg: 'License status updated successfully',
  taxUpdateMsg: 'W9 status updated successfully',
  taxApproveMsg: 'W9 compliance approved successfully',
  taxtRejectMsg: 'W9 compliance rejected successfully',
  npnApproveMsg: 'NPN compliance approved successfully',
  npnRejectMsg: 'NPN compliance rejected successfully',
  enoUpdateMsg: 'E&O status updated successfully',
  enoApproveMsg: 'E&O compliance approved successfully',
  enoRejectMsg: 'E&O compliance rejected successfully',
  regulatoryActionUpdateMsg: 'Regulatory Action updated successfully',
  passwordMessage:
    'Must contain at least one number and one uppercase and lowercase letter and at least 8 or more characters',
  changeSubPrPasswordMessage1: 'Your password has been updated.',
};

// Compliance Status
export const complianceStatus = {
  updated: 'updated',
  approved: 'approved',
  rejected: 'rejected',
  in_compliance: 'in_compliance',
  invalid: 'invalid',
};

// get current financial Year with month.
export const getCurrentFinancialYear = (withMonth) => {
  let fiscalyear = { start: '', end: '' };
  const today = new Date();

  fiscalyear = {
    start: withMonth
      ? moment(today).subtract(12, 'months').format('MMMM YYYY')
      : parseInt(moment(today).subtract(12, 'months').format('YYYY')),
    end: withMonth
      ? moment(today).subtract(1, 'months').format('MMMM YYYY')
      : parseInt(moment(today).subtract(1, 'months').format('YYYY')),
  };

  return fiscalyear;
};

/*
1. The method returns 1 if startDate is greater than endDate
2. The method returns 0 if startDate equals endDate
3. The method returns -1 if startDate is less than endDate
*/
export const compareDates = (startDate, endDate) => {
  var momentA = moment(startDate, 'MMMM YYYY');
  var momentB = moment(endDate, 'MMMM YYYY');
  if (momentA > momentB) return 1;
  else if (momentA < momentB) return -1;
  else return 0;
};

// get count for financial year
export const getCountForPoliciesDetails = (details, totalKey, aggregateKey) => {
  let mappedDetails = {
    [aggregateKey]: 0,
    carrierNames: {},
  };

  forEach(details, (data, year) => {
    // check for valid fiscal year only.
    if (
      Number(year) === getCurrentFinancialYear(false).start ||
      Number(year) === getCurrentFinancialYear(false).end
    ) {
      forEach(data, (value, month) => {
        let currentDate = month.trim() + ' ' + year; // form a month year format
        currentDate = moment(currentDate, 'MMMM YYYY');
        let start = moment(getCurrentFinancialYear(true).start, 'MMMM YYYY');
        let end = moment(getCurrentFinancialYear(true).end, 'MMMM YYYY');

        if (
          (compareDates(currentDate, start) === 0 ||
            compareDates(currentDate, start) === 1) &&
          (compareDates(currentDate, end) === 0 ||
            compareDates(currentDate, end) === -1)
        ) {
          if (!isEmpty(value)) {
            map(value, (carrier, key) => {
              mappedDetails.carrierNames = {
                ...mappedDetails.carrierNames,
                [key]: key,
              };
            });
          }
          const result = {};
          for (const carrier in value) {
            const totalResultKey = value[carrier].new + value[carrier].renew;
            result[carrier] = totalResultKey;
          }

          let policiesNew = 0;
          let policiesRenew = 0;
          let premiumNew = 0;
          let premiumRenew = 0;

          if (totalKey === 'policy') {
            for (const carrier in value) {
              policiesNew += value[carrier].new;
              policiesRenew += value[carrier].renew;
            }
          }

          if (totalKey === 'amount') {
            for (const carrier in value) {
              premiumNew += value[carrier].new;
              premiumRenew += value[carrier].renew;
            }
          }
          if (start && end) {
            const summedValue = sum(valuesIn(value)) ? sum(valuesIn(value)) : 0;

            if (totalKey === 'amount') {
              mappedDetails = {
                ...mappedDetails,
                [aggregateKey]: (mappedDetails[aggregateKey] += summedValue), // count valid values.
                [month]: {
                  ...result,
                  [totalKey]: summedValue,
                  month,
                  year,
                  name: monthNameMappings[month],
                  premiumNew,
                  premiumRenew,
                },
              };
            }

            if (totalKey === 'policy') {
              mappedDetails = {
                ...mappedDetails,
                [aggregateKey]: (mappedDetails[aggregateKey] += summedValue), // count valid values.
                [month]: {
                  ...result,
                  [totalKey]: summedValue,
                  month,
                  year,
                  name: monthNameMappings[month],
                  policiesNew,
                  policiesRenew,
                },
              };
            }
          }
        }
      });
    }
  });
  return { ...mappedDetails };
};

export const nachaErrorMessage =
  'Please download this file to view the errors in processing. These errors need to be corrected in order to Publish the commissions.';

export const encodeParams = (id, code) => {
  let encodedQueryString = null;
  const queryString = `id=${id?.toString()}&code=${code?.toString()}`;
  encodedQueryString = encryptUrlParams(queryString);
  return encodedQueryString;
};

export const rowClicked = (event, rowData, props) => {
  event.preventDefault();
  const { producer_code, producer_id } = rowData;
  urlChangeCallback(producer_id, producer_code, props);
};

// Update url
export const urlChangeCallback = (id, code, props) => {
  let encodedQueryString = encodeParams(id, code);
  const { url } = props.match;
  const agentUrl = url.substring(0, url.lastIndexOf('/'));
  props.history.push({
    pathname: `${agentUrl}/${encodedQueryString}`,
    state: {
      filterStatus: props.filterStatus,
    },
  });
};

export const getAgentDirectoryUrl = (producer_id, producer_code, props) => {
  let encodedQueryString = encodeParams(producer_id, producer_code);
  const agentUrl = props.location.pathname.substring(
    0,
    props.location.pathname.lastIndexOf('/')
  );
  const agentDirectoryUrl = `${agentUrl}/${encodedQueryString}`;
  return agentDirectoryUrl;
};

export const replaceWithPlaceholder = (content, text) =>
  content.replace(/__PLACEHOLDER__/g, text);

export const getNextMonth = () => {
  return `<b> ${moment().add(1, 'months').format('MM/DD/YYYY')}</b>`;
};

export const getItemIndex = (a, b) => {
  let bLicenseType =
    b.licenseType.toLowerCase() === FIRM.toLowerCase()
      ? 'Business'
      : b.licenseType.toLowerCase();

  let newLicenseClass = !isEmpty(get(b, 'licenseClass', ''))
    ? b.licenseClass.toLowerCase()
    : '';
  let index = findIndex(a, (item) => {
    let itemLicenseType =
      item.licenseType.toLowerCase() === FIRM.toLowerCase()
        ? 'Business'
        : item.licenseType.toLowerCase();
    let checkData =
      item.license.toLowerCase() === b.license.toLowerCase() &&
      item.stateData.toLowerCase() === b.stateData.toLowerCase() &&
      item.loa.toLowerCase() === b.loa.toLowerCase() &&
      itemLicenseType === bLicenseType &&
      item.licenseClass.toLowerCase() === newLicenseClass;
    return checkData;
  });
  return index;
};

// Check for carrier name
export const checkCarrierNameExist = (carrier, list) => {
  let displayname = carrier.toLowerCase();
  var i;
  for (i = 0; i < list.length; i++) {
    if (list[i].display_name.toLowerCase() === displayname) {
      return true;
    }
  }
  return false;
};

// Check for carrier name for update
export const checkCarrierNameExistUpdate = (carrier, id, list) => {
  let displayname = carrier.toLowerCase();
  let carrierList = list;
  var i;
  if (!isEmpty(carrierList)) {
    for (i = 0; i < carrierList.length; i++) {
      if (
        carrierList[i].display_name.toLowerCase() === displayname &&
        carrierList[i].id !== id
      ) {
        return true;
      }
    }
  }
  return false;
};

// Add commission state filter
export const addCommissionStatefilter = (
  commissionState,
  markets,
  isTierCommission,
  is_tiers_enabled
) => {
  let stateArr = [];
  let marketsArray = [ALL_STATES, ...markets];
  if (!isTierCommission || !is_tiers_enabled) {
    let filteredStateArray = [];
    for (var i = 0; i < commissionState.length; i++) {
      stateArr.push(commissionState[i].state_abbrevation);
    }
    if (stateArr.length > 0) {
      filteredStateArray = marketsArray.filter((stateCode) => {
        return stateArr.filter((c) => c === stateCode).length < 3 && stateCode;
      });
      return filteredStateArray;
    }
  }
  return marketsArray;
};

export const simplifyPhoneNumber = (number) => {
  return !isEmpty(number) ? startCase(number).replace(/\D/g, '') : '';
};

export const getEncodedUrl = (id, code) => {
  if (!!id && !!code) {
    const queryString = `id=${id.toString()}&code=${code.toString()}`;
    return encryptUrlParams(queryString);
  }
};

export const sortStringData = (a, b) => {
  a = a === null ? '' : '' + a.replace(/-|\s/g, '').toLowerCase();
  b = b === null ? '' : '' + b.replace(/-|\s/g, '').toLowerCase();
  return a > b ? 1 : a === b ? 0 : -1;
};

export const dateSort = (a, b) => {
  let dateA = a;
  let dateB = b;
  if (dateA === HTML_EM) {
    dateA = 0;
  }
  if (dateB === HTML_EM) {
    dateB = 0;
  }
  dateA = new Date(dateA).getTime();
  dateB = new Date(dateB).getTime();
  return dateA - dateB;
};

export const dateAndTimeSort = (a, b) => {
  if (a === HTML_EM || !!!a) {
    a = 0;
  }
  if (b === HTML_EM || !!!b) {
    b = 0;
  }
  let dateA = a.toString().split(' ')[0];
  let dateB = b.toString().split(' ')[0];
  dateA = new Date(dateA);
  dateB = new Date(dateB);
  return dateA - dateB;
};

export const isObject = (objValue) => {
  return (
    objValue && typeof objValue === OBJECT && objValue.constructor === Object
  );
};

export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

export const checkCommissionValues = (commissions) => {
  let commissionValues = commissions.filter(
    (item) =>
      item.agent_new_commission > MAX_COMMISSION_PERCENTAGE_ALLOWED ||
      item.agent_renewal_commission > MAX_COMMISSION_PERCENTAGE_ALLOWED ||
      item.broker_new_commission > MAX_COMMISSION_PERCENTAGE_ALLOWED ||
      item.broker_renewal_commission > MAX_COMMISSION_PERCENTAGE_ALLOWED
  );
  return commissionValues.length > 0 ? true : false;
};

export const getFileName = (filePath) => {
  return filePath.replace(/^.*[\\//]/, '');
};

export const MEDIA_TYPE = {
  PDF: 'PDF',
  IMG: 'IMG',
};

export const MEDIA_TYPES = {
  [MEDIA_TYPE.PDF]: ['pdf'],
  [MEDIA_TYPE.IMG]: ['jpg', 'jpeg', 'png', 'gif'],
};
export const urlOpenInNewTab = (id, code, url) => {
  let encodedQueryString = null;
  const queryString = `id=${id.toString()}&code=${code.toString()}`;
  encodedQueryString = encryptUrlParams(queryString);
  let newUrl;
  if (window.location.href.includes('my-account')) {
    newUrl = url;
  } else {
    newUrl = url.substring(0, url.lastIndexOf('/'));
  }
  window.open(
    `${window.location.origin}${newUrl}/${encodedQueryString}`,
    '_blank'
  );
};

// Post signup tax and w9
export const taxContent =
  '<p>Your submission of the information below certifies that the information provided herein is current, complete and correct and you authorize First Connect to verify the information submitted.</p><p>First Connect is required by law to obtain tax information from you when making a reportable payment to you. If you do not provide us with this information, your payment maybe subject to a 30% federal income tax backup with holding. Also if you do not provide us with this information, you may be subject additional penalties.</p>';

export const licenseAndEnoContent =
  '<p>Individual and Agency license must include both property & casualty lines of business and be active and valid in all states in which the agent and/or agency wish to write business. First Connect will use a third party validation services to verify the accuracy of the license information provided.</p>';
export const acceptTermsAndCondition =
  'I have read and accept the "Terms & Conditions".';
export const enoContent = `<p>Member producer must maintain an in force errors and omission to avoid being in breach of contract with First Connect Insurance Services (FCIS).</p>
  <p>Required minimum limits of $1,000,000 per occurrence and $1,000,000 aggregate.
  </p><p>Acceptable uploads include certificate of coverage, declaration page or Accord form.</p>`;

export const checkBoxText = `I don't have one`;

export const acceptOfflineAgreement =
  'I acknowledge and accept this previously signed agreement.';

export const securityQuestionOptions = [
  {
    value: 'Select',
    label: '',
  },
  {
    value: 'What was the house number and street name you lived in as a child?',
    label: 'option1',
  },
  {
    value: 'What were the last four digits of your childhood telephone number?',
    label: 'option2',
  },
  {
    value: 'What primary school did you attend?',
    label: 'option3',
  },
  {
    value: 'In what town or city was your first full time job?',
    label: 'option4',
  },
  {
    value: 'In what town or city did you meet your spouse/partner?',
    label: 'option5',
  },
  {
    value: 'What is the middle name of your oldest child?',
    label: 'option6',
  },
  {
    value: `What are the last five digits of your driver's licence number?`,
    label: 'option7',
  },
  {
    value: `What is your grandmother's (on your mother's side) maiden name?`,
    label: 'option8',
  },
  {
    value: `What is your spouse or partner's mother's maiden name?`,
    label: 'option9',
  },
  {
    value: `In what town or city did your mother and father meet?`,
    label: 'option10',
  },
  {
    value: `What time of the day were you born?`,
    label: 'option11',
  },
  {
    value: `	What time of the day was your first child born?`,
    label: 'option12',
  },
];

export const subProducerAgencyOptions = [
  {
    value: '',
    label: 'Select',
  },
  {
    value: '0-2',
    label: '0-2 agents',
  },
  {
    value: '3-5',
    label: '3-5 agents',
  },
  {
    value: '6-10',
    label: '6-10 agents',
  },
  {
    value: '10+',
    label: 'More than 10 agents',
  },
];
export const agencyProfileContent =
  '<p>If signing up as an agency, use your business name.<br /> If signing up as an independent agent, use your name.</p>';

export const teamMemberAddedMessages = {
  teamMemberAddedTitle: 'Team Member added successfully!',
  content: `Your team member will receive an email shortly with<br> a link to easily complete their registration.<br><br>If they do not receive it, please verify their email<br> address and have them check their spam/ junk folder.`,
};

export const dollarRange = [
  {
    value: '',
    label: 'Select',
  },
  {
    value: '$0 - $1 million',
    label: 'Under $1 Million',
  },
  {
    value: '$1 million - $2 million',
    label: '$1 - $2 Million',
  },
  {
    value: '$2 million - $5 million',
    label: '$2 - $5 Million',
  },
  {
    value: '$5 million',
    label: 'Over $5 Million',
  },
];
export const agencyDetailsContent =
  '<p>Please provide these additional details about your agency so we can better serve you.</p>';

export const aboutFirstconnectDropdown = [
  {
    value: '',
    label: 'Select',
  },
  {
    value: 'Agent Referral',
    label: 'Agent Referral',
  },
  {
    value: 'Carrier Referral',
    label: 'Carrier Referral',
  },
  {
    value: 'Convention',
    label: 'Convention',
  },
  {
    value: 'Email Marketing',
    label: 'Email Marketing',
  },
  {
    value: 'Facebook',
    label: 'Facebook',
  },
  {
    value: 'Linkedln',
    label: 'Linkedln',
  },
  {
    value: 'Mail Marketing',
    label: 'Mail Marketing',
  },
  {
    value: 'Search Engine',
    label: 'Search Engine',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

const stateToAbbreviationMap = {
  alaska: 'AK',
  alabama: 'AL',
  arkansas: 'AR',
  arizona: 'AZ',
  california: 'CA',
  colorado: 'CO',
  connecticut: 'CT',
  'washington dc': 'DC',
  delaware: 'DE',
  florida: 'FL',
  georgia: 'GA',
  hawaii: 'HI',
  iowa: 'IA',
  idaho: 'ID',
  illinois: 'IL',
  indiana: 'IN',
  kansas: 'KS',
  kentucky: 'KY',
  louisiana: 'LA',
  massachusetts: 'MA',
  maryland: 'MD',
  maine: 'ME',
  michigan: 'MI',
  minnesota: 'MN',
  missouri: 'MO',
  mississippi: 'MS',
  montana: 'MT',
  'north carolina': 'NC',
  'north dakota': 'ND',
  nebraska: 'NE',
  'new hampshire': 'NH',
  'new jersey': 'NJ',
  'new mexico': 'NM',
  nevada: 'NV',
  'new york': 'NY',
  ohio: 'OH',
  oklahoma: 'OK',
  oregon: 'OR',
  pennsylvania: 'PA',
  'rhode island': 'RI',
  'south carolina': 'SC',
  'south dakota': 'SD',
  tennessee: 'TN',
  texas: 'TX',
  utah: 'UT',
  virginia: 'VA',
  vermont: 'VT',
  washington: 'WA',
  wisconsin: 'WI',
  'west virginia': 'WV',
  wyoming: 'WY',
};

export const getStateNameToStateCode = (value) => {
  if (!value) {
    return false;
  }
  return stateToAbbreviationMap[value.toLowerCase()];
};

export const carrier_url_regex =
  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

export const trimHypenFromString = (value) => {
  return value.replace(/-/g, '');
};

// Return route
export const getDefaultPath = (data) => {
  const { match, module, enableMenuItem, isNewCarrierStoreEnabled } = data;
  let isImpersonateModeOn = !!localStorage.getItem('isImpersonatedDataPresent')
    ? JSON.parse(localStorage.getItem('isImpersonatedDataPresent'))
    : false;
  let roleCategory = localStorage.getItem('role_category');
  let hasAppointmentConsoleAccess =
    localStorage.getItem('hasAppointmentConsoleAccess') &&
    JSON.parse(localStorage.getItem('hasAppointmentConsoleAccess'));
  let path = null;
  const isSubProducer = isImpersonateModeOn
    ? isUserSubProducer(localStorage.getItem('impersonateSignupFlow'))
    : isUserSubProducer(localStorage.getItem('signupFlow'));
  const isPrincipalAccessProducer =
    localStorage.getItem('role') === PRINCIPAL_ACCESS_ROLE;

  const shouldShowCarrierStore =
    (!isSubProducer || isPrincipalAccessProducer) &&
    shouldRedirectToCarrierStore(
      data.carrierStoreAgentApplicationRestrictionEnabled,
      data.carrierAppointmentRequestCount,
      enableMenuItem,
      isNewCarrierStoreEnabled
    );
  if (isImpersonateModeOn || roleCategory !== ADMIN) {
    if (shouldShowCarrierStore) {
      if (roleCategory === ADMIN) {
        // Admins shouldn't be redirected to /start page
        path = `${match.url}/carrier-store`;
      } else {
        // In the past, this was redirect to the carrier store page
        // Now, initial redirect is handled by the logic on new frontend
        path = `${match.url}/start`;
      }
    } else if (
      module[DASHBOARD] &&
      (module?.[DASHBOARD]?.[UPCOMING_RENEWALS] ||
        module?.[DASHBOARD]?.[TOTAL_POLICY_TILL_DATE] ||
        module?.[DASHBOARD]?.[PRODUCTION_ACCESS] ||
        module?.[DASHBOARD]?.[QUOTES.toUpperCase()] ||
        module?.[DASHBOARD]?.[TOTAL_PREMIUM_TILL_DATE])
    ) {
      if (roleCategory === ADMIN) {
        // Admins shouldn't be redirected to /start page
        path = `${match.url}/dashboard`;
      } else {
        // In the past, this was redirect to the dashboard page
        // Now, initial redirect is handled by the logic on new frontend
        path = `${match.url}/start`;
      }
    } else if (module[MY_COMMISSION] && module[MY_COMMISSION].read) {
      path = `${match.url}/my-commission`;
    } else if (
      module[MY_ACCOUNT] &&
      (module[MY_ACCOUNT][AGENT_PROFILE] ||
        module[MY_ACCOUNT][ENO_INFORMATION] ||
        module[MY_ACCOUNT][LICENSE] ||
        module[MY_ACCOUNT][W9_INFORMATION] ||
        module[MY_ACCOUNT][BANK_INFORMATION] ||
        module[MY_ACCOUNT][DOCUMENTS] ||
        module[MY_ACCOUNT][SUB_AGENT] ||
        module[MY_ACCOUNT][TOTAL_POLICY_TILL_DATE] ||
        module[MY_ACCOUNT][TOTAL_PREMIUM_TILL_DATE] ||
        module[MY_ACCOUNT][POLICY_BY_CARRIER] ||
        module[MY_ACCOUNT][COMMISSION_INCENTIVE_YTD])
    ) {
      path = `${match.url}/my-account`;
    } else if (module[MY_POLICIES] && module[MY_POLICIES].read) {
      path = `${match.url}/my-policies`;
    } else if (module[AGENCY_COMMISSION] && module[AGENCY_COMMISSION].read) {
      path = `${match.url}/agency-commission`;
    } else if (module[QUOTES] && module[QUOTES].read) {
      path = `${match.url}/quotes`;
    } else if (
      module[AGENCY_PROFILE_MENU] &&
      module[AGENCY_PROFILE_MENU].read
    ) {
      path = `${match.url}/agency-profile`;
    }
  } else {
    if (
      module[AGENT_DIRECTORY] &&
      (module[AGENT_DIRECTORY][AGENT_DIRECTORY_ALL] ||
        module[AGENT_DIRECTORY][AGENT_DIRECTORY_ACTIVE] ||
        module[AGENT_DIRECTORY][AGENT_DIRECTORY_PENDING] ||
        module[AGENT_DIRECTORY][AGENT_DIRECTORY_RESTRICTED] ||
        module[AGENT_DIRECTORY][AGENT_DIRECTORY_SUSPEND] ||
        module[AGENT_DIRECTORY][AGENT_DIRECTORY_DORMANT] ||
        module[AGENT_DIRECTORY][AGENT_DIRECTORY_TERMINATED] ||
        module[AGENT_DIRECTORY][AGENT_DIRECTORY_INCOMPLETE_SIGNUP] ||
        module[AGENT_DIRECTORY][AGENT_DIRECTORY_ERROR_AND_OMMISSION] ||
        module[AGENT_DIRECTORY][AGENT_DIRECTORY_NPN] ||
        module[AGENT_DIRECTORY][AGENT_DIRECTORY_W9] ||
        module[AGENT_DIRECTORY][AGENT_DIRECTORY_REGULATORY_ACTION])
    ) {
      path = `${match.url}/agent-directory`;
    } else if (module[COMMISSIONS] && module[COMMISSIONS].read) {
      path = `${match.url}/commissions`;
    } else if (
      module[CARRIERS] &&
      module[CARRIERS].read &&
      hasAppointmentConsoleAccess
    ) {
      path = `${match.url}/carriers`;
    } else if (module[ADHOC_PAYMENTS] && module[ADHOC_PAYMENTS].read) {
      path = `${match.url}/adhoc-payments`;
    } else if (
      module[REPORTS] &&
      (module[REPORTS][AGENT_ONBOARDING_REPORTS] ||
        module[REPORTS][AGENT_SYNC_REPORTS] ||
        module[REPORTS][QUICKBOOKS_EXTRACT] ||
        module[REPORTS][MOTION_AUTO_DOCUMENT] ||
        module[REPORTS][FAST_START_REPORT] ||
        module[REPORTS][APPOINTMENT_FAILURE_REPORT])
    ) {
      if (
        module[REPORTS][MONTHLY_COMMISSION_REPORT] &&
        module[REPORTS][MONTHLY_COMMISSION_REPORT].read
      ) {
        path = `${match.url}/monthly-commission-report`;
      } else if (
        module[REPORTS][AGENT_ONBOARDING_REPORTS] &&
        module[REPORTS][AGENT_ONBOARDING_REPORTS].read
      ) {
        path = `${match.url}/agent-on-boarding`;
      } else if (
        module[REPORTS][AGENT_SYNC_REPORTS] &&
        module[REPORTS][AGENT_SYNC_REPORTS].read
      ) {
        path = `${match.url}/agent-sync-report`;
      } else if (
        module[REPORTS][QUICKBOOKS_EXTRACT] &&
        module[REPORTS][QUICKBOOKS_EXTRACT].read
      ) {
        path = `${match.url}/quickbooks-extract`;
      } else if (
        module[REPORTS][FAST_START_REPORT] &&
        module[REPORTS][FAST_START_REPORT].read
      ) {
        path = `${match.url}/fast-start-report`;
      } else if (
        module[REPORTS][INCENTIVE_REPORT] &&
        module[REPORTS][INCENTIVE_REPORT].read
      ) {
        path = `${match.url}/incentive-report`;
      } else if (
        module[REPORTS][APPOINTMENT_FAILURE_REPORT] &&
        module[REPORTS][APPOINTMENT_FAILURE_REPORT].read
      ) {
        path = `${match.url}/appointment-failure-report`;
      } else if (
        module[REPORTS][MOTION_AUTO_DOCUMENT] &&
        module[REPORTS][MOTION_AUTO_DOCUMENT].read
      ) {
        path = `${match.url}/motion-auto-s3-bucket`;
      } else if (
        module[REPORTS][CARRIER_LOB_MARKETS_REPORT] &&
        module[REPORTS][CARRIER_LOB_MARKETS_REPORT].read
      ) {
        path = `${match.url}/carrier-lob-markets-report`;
      } else if (
        module[REPORTS][REWARDS_PROGRAM_REPORT] &&
        module[REPORTS][REWARDS_PROGRAM_REPORT].read
      ) {
        path = `${match.url}/rewards-program-report`;
      }
    } else if (module[ROLES] && module[ROLES].read) {
      path = `${match.url}/permission/roles`;
    } else if (
      module[PORTAL_SETTINGS] &&
      (module[PORTAL_SETTINGS][PRODUCTS_MENU] ||
        module[PORTAL_SETTINGS][LOA_FILTERS])
    ) {
      if (module[PORTAL_SETTINGS][PRODUCTS_MENU]?.read) {
        path = `${match.url}/product`;
      } else if (module[PORTAL_SETTINGS][LOA_FILTERS]?.read) {
        path = `${match.url}/loa-filters`;
      }
      if (module[PORTAL_SETTINGS][ADMIN_USERS_MENU]?.read) {
        path = `${match.url}/admin-users`;
      }
      if (module[PORTAL_SETTINGS][BULK_APPOINTMENTS]?.read) {
        path = `${match.url}/admin-users`;
      }
      if (module[PORTAL_SETTINGS][AGENT_MESSAGING]?.read) {
        path = `${match.url}/agent-messaging`;
      }
    }
  }
  return path;
};

export const carrierStatus = [
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Inactive',
    value: 'Inactive',
  },
];

export const adminUserRoles = [
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'Super-admin',
    value: 'super-admin',
  },
];

export const getModulePermission = (
  adminPermission,
  impersonatePermission,
  isImpersonate
) => {
  if (!!isImpersonate && isImpersonate) {
    return impersonatePermission;
  } else {
    return adminPermission;
  }
};

export const getAuthDetails = (data) => {
  const { auth, producer, isImpersonate } = data;

  if (isImpersonate && !!producer) {
    return {
      ...producer,
      userRole: auth.userRole,
      isWholesaler: producer.is_wholesaler,
      showSSNEditPrompt: auth?.showSSNEditPrompt,
    };
  } else {
    return { ...auth };
  }
};

export const getParentProducerId = () => {
  let isImpersonateActive = !!localStorage.getItem('isImpersonatedDataPresent')
    ? JSON.parse(localStorage.getItem('impersonateData'))
    : false;
  let impersonatedDetails = !!localStorage.getItem('impersonateData')
    ? JSON.parse(localStorage.getItem('impersonateData'))
    : null;
  let impersonatedParentProducerId =
    !!impersonatedDetails &&
    !!impersonatedDetails?.producer?.fkParentProducerId &&
    impersonatedDetails.producer.fkParentProducerId;
  let parentProducerId = JSON.parse(localStorage.getItem('fkParentProducerId'));
  parentProducerId = isImpersonateActive
    ? impersonatedParentProducerId
    : parentProducerId;
  return parentProducerId;
};

export const imagePdfFileTypeMessage = 'Please upload image/pdf files only';
export const instructionFileTypeMessage =
  'Please upload jpg, jpeg, png, pdf, docx files only.';

export const myAccountPermissionAccess = (module) => {
  let hasMyAccountAccess =
    module[MY_ACCOUNT][AGENT_PROFILE] ||
    module[MY_ACCOUNT][ENO_INFORMATION] ||
    module[MY_ACCOUNT][LICENSE] ||
    module[MY_ACCOUNT][W9_INFORMATION] ||
    module[MY_ACCOUNT][BANK_INFORMATION] ||
    module[MY_ACCOUNT][DOCUMENTS] ||
    module[MY_ACCOUNT][SUB_AGENT] ||
    module[MY_ACCOUNT][TOTAL_POLICY_TILL_DATE] ||
    module[MY_ACCOUNT][TOTAL_PREMIUM_TILL_DATE] ||
    module[MY_ACCOUNT][POLICY_BY_CARRIER] ||
    module[MY_ACCOUNT][COMMISSION_INCENTIVE_YTD];
  return hasMyAccountAccess;
};
export const ShowImageAfterLoading = (prop) => {
  const { imageUrl, label, loading } = prop;
  return (
    <React.Fragment>
      <img
        src={imageUrl}
        className="table-column-img img-fluid"
        title={label}
        alt={label}
      />
      {loading && (
        <Skeleton className="table-skeleton" height={40} width={60} />
      )}
    </React.Fragment>
  );
};

export const productionAccessPermission = (module) => {
  let hasProductionAccess =
    module && module[DASHBOARD] && module[DASHBOARD]?.[PRODUCTION_ACCESS]?.read;
  return hasProductionAccess;
};

export const decryptModulePermissions = (data) => {
  console.log('deprecated function called: decryptModulePermissions');
  const decoded = JSON.parse(atob(data.split('.')[1]));
  return decoded.permissions;
};

export const capitalize = (str) => {
  if (typeof str !== STRING) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const themeList = [
  { name: 'Navy Blue', value: 'navy-blue' },
  { name: 'Purplish Blue', value: 'purplish-blue' },
  { name: 'Sky Blue', value: 'sky-blue' },
];

export const dateType = [
  {
    label: 'Membership Date',
    value: 'membershipDate',
  },
  {
    label: 'Activation Date',
    value: 'activationDate',
  },
];

export const stringIsEmpty = (value) => {
  return S(value).isEmpty();
};

export const removeFileExtension = (value) => {
  if (!stringIsEmpty(value)) {
    return value.replace(/\.[^/.]+$/, '');
  }

  return value;
};

export const getFileExtension = (value) => {
  if (stringIsEmpty(value)) {
    return '';
  }

  return value.match(/\.[^/.]+$/)[0] || '';
};

export const checkFileNameOrThrowError = (fileName) => {
  const nullByteRegex = FILE_PATH_NULL_BYTE_CHECK;
  const noForbiddenFilenameCharactersRegex =
    forbidden_filename_characters_regex;
  const filenameStartingWithDotRegex = filename_starting_with_dot;
  if (
    !noForbiddenFilenameCharactersRegex.test(fileName) ||
    nullByteRegex.test(fileName) ||
    filenameStartingWithDotRegex.test(fileName)
  ) {
    return true;
  }
};

export const validateFileName = (file, type) => {
  if (isEmpty(file)) {
    return REQUIRED;
  } else if (file[0]?.name && checkFileNameOrThrowError(file[0].name)) {
    return INVALID_FILE_NAME;
  } else if (type == RENAME && checkFileNameOrThrowError(file)) {
    return INVALID_FILE_NAME;
  } else {
    return '';
  }
};

export const fileTypeCheck = (value) => {
  const validExts = ['xlsx', 'xls'];
  if (validExts.includes(value?.split('.')?.slice(-1)[0])) {
    return true;
  } else return false;
};

export const uploadFile = async (file, file_url) => {
  let formData = new FormData();
  formData.append('file', file);
  formData.append('filePath', file_url);
  try {
    const result = await axios.post('/api/v1/vendor/upload-file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return result;
  } catch (e) {
    return e;
  }
};

export const uploadMultipleFile = async (files, file_urls) => {
  let formData = new FormData();
  formData.append('filePaths', file_urls);
  for (let file of files) {
    formData.append('files', file[0]);
  }
  try {
    const result = await axios.post(
      '/api/v1/vendor/upload-file-multiple',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return result;
  } catch (e) {
    return e;
  }
};

export const updateProducer = async (dataSet) => {
  try {
    const result = await axios.post(
      '/api/v1/producer/update-producer',
      dataSet
    );
    return result;
  } catch (e) {
    return e;
  }
};

export const updateComplianceFile = async (dataSet) => {
  try {
    const result = await axios.post('/api/v1/producer/update-file', dataSet);
    return result;
  } catch (e) {
    return e;
  }
};

export const suggestedCSECarriers = {
  tend: {
    carrierType: 'Warranty',
    carrierLabel: 'Tend Home Warranty',
    purchase: '$32',
    month: '/mo',
    commission: ' 12%',
  },
  blink: {
    carrierType: 'Cyber',
    carrierLabel: 'Blink Cyber',
    purchase: '$12',
    month: '/mo',
    commission: ' 13%',
  },
  'annex-risk-solutions': {
    carrierType: 'Flood',
    carrierLabel: 'Flood Rater',
    commission: '',
  },
};

export const toolTipsEnOValue =
  'Member must maintain Errors and Omissions insurance covering Member and its employees with limits of at least $1,000,000. You must provide evidence of E&O coverage.';

export const toolTipsW9Value =
  'Your submission of the information below certifies that the information provided herein is current, complete and correct and you authorize First Connect to verify the information submitted. First Connect is required by law to obtain tax information from you when making a reportable payment to you.';

export const toolTipsLicenseValue =
  'Individual and Agency Licenses must include the lines for which quotes are being sought in each state.  First Connect uses a third party to validate license information.';

export const isTabExists = (value) => {
  return window.location.pathname.includes(value);
};

export const getNextedTernaryDetails = (
  condition,
  firstResponse,
  secondResponse = null
) => {
  return condition ? firstResponse : secondResponse;
};

export const getTokenPayload = (value) => {
  return JSON.parse(atob(value.split('.')[1]));
};

export const isCarrierStore = (
  carrierStoreAgentApplicationRestrictionEnabled,
  carrierAppointmentRequestCount,
  isEnabledMenuBar,
  isNewCarrierStoreEnabled
) => {
  if (isNewCarrierStoreEnabled) {
    return false;
  }
  let isImpersonateModeOn = !!localStorage.getItem('isImpersonatedDataPresent')
    ? JSON.parse(localStorage.getItem('isImpersonatedDataPresent'))
    : false;
  const availableCarrierCount = JSON.parse(
    localStorage.getItem('availableCarrierCount') || null
  );
  let roleCategory = localStorage.getItem('role_category');
  const isSubProducer =
    isUserSubProducer(localStorage.getItem('signupFlow')) ||
    isUserSubProducer(localStorage.getItem('impersonateSignupFlow'));
  const isPrincipalAccessProducer =
    localStorage.getItem('role') === PRINCIPAL_ACCESS_ROLE;

  if (
    (isImpersonateModeOn || roleCategory !== ADMIN) &&
    (!isSubProducer || isPrincipalAccessProducer) &&
    carrierStoreAgentApplicationRestrictionEnabled &&
    !isEnabledMenuBar &&
    (!carrierAppointmentRequestCount || carrierAppointmentRequestCount <= 3)
  ) {
    return (
      availableCarrierCount >
        MINIMUM_AVALIABLE_CARRIERS_COUNT_TO_LOCK_PORTAL_USAGE &&
      !isIOS &&
      !isMobileOnly
    );
  } else {
    return false;
  }
};

/**
 * Determines whether the email verification modal can be seen on page load.
 *
 * Almost exact copy of isCarrierStore() function. Should be refactored with carrier store migration.
 * This function is used to check if the user is eligible for showing email verification modal
 *
 * @param {boolean} carrierStoreAgentApplicationRestrictionEnabled - Flag indicating if carrier store agent application restriction is enabled.
 * @param {number} carrierAppointmentRequestCount - The count of carrier appointment requests.
 * @param {boolean} isEnabledMenuBar - Flag indicating if the menu bar is enabled.
 * @returns {boolean} - True if the email verification modal can be seen, false otherwise.
 */
export const canSeeEmailVerificationModalOnPageLoad = (
  carrierStoreAgentApplicationRestrictionEnabled,
  carrierAppointmentRequestCount,
  isEnabledMenuBar
) => {
  const isNewSignUpFlowEnabled = getIsNewSignUpFlowEnabled();

  if (!isNewSignUpFlowEnabled) {
    return false;
  }

  let isImpersonateModeOn = !!localStorage.getItem('isImpersonatedDataPresent')
    ? JSON.parse(localStorage.getItem('isImpersonatedDataPresent'))
    : false;
  const availableCarrierCount = JSON.parse(
    localStorage.getItem('availableCarrierCount') || null
  );
  let roleCategory = localStorage.getItem('role_category');
  const isSubProducer =
    isUserSubProducer(localStorage.getItem('signupFlow')) ||
    isUserSubProducer(localStorage.getItem('impersonateSignupFlow'));
  const isPrincipalAccessProducer =
    localStorage.getItem('role') === PRINCIPAL_ACCESS_ROLE;

  if (
    (isImpersonateModeOn || roleCategory !== ADMIN) &&
    (!isSubProducer || isPrincipalAccessProducer) &&
    carrierStoreAgentApplicationRestrictionEnabled &&
    !isEnabledMenuBar &&
    (!carrierAppointmentRequestCount || carrierAppointmentRequestCount <= 3) &&
    // Different conditions vs in isCarrierStore() function:
    // 1. Mobile users can see the email verification modal
    (isIOS ||
      isMobileOnly ||
      // 2. Agents with small count of available carriers can see the email verification modal
      availableCarrierCount <=
        MINIMUM_AVALIABLE_CARRIERS_COUNT_TO_LOCK_PORTAL_USAGE)
  ) {
    return true;
  } else {
    return false;
  }
};

export const isCarrierStoreSubProducer = (isWholesaler) => {
  if (!isWholesaler) {
    return true;
  }
  return false;
};

export const isUserSubProducer = (signupFlow) => {
  const isSubProducer = [ALTERNATE_SUB_PRODUCER, SUB_PRODUCER_KEY].includes(
    signupFlow
  );
  return isSubProducer;
};

export const allowedParams = [
  'fn',
  'ln',
  'an',
  'em',
  'pn',
  'ct',
  'refer',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
];

export const mapQuery = (query) => {
  const keyMap = {
    fn: 'first_name',
    ln: 'last_name',
    an: 'agency_name',
    em: 'email',
    pn: 'phone_number',
    ct: 'city',
    st: 'state',
    utm_source: 'utm_source',
    refer: 'referred_code',
  };

  const mappedValues = {};
  for (const [key, value] of query.entries()) {
    if (['fn', 'ln'].includes(key)) {
      mappedValues[keyMap[key]] = getStartCase(value?.toLowerCase()?.trim());
    } else {
      mappedValues[keyMap[key] || key] = value?.trim() || value;
    }
  }
  return mappedValues;
};

export const queryParam = (props) => {
  if (!!localStorage.getItem(AUTHORIZATION)) {
    props.history?.push('/');
  }

  const query = new URLSearchParams(props.location?.search);
  const filteredParams = new URLSearchParams();
  for (const [key, value] of query) {
    if (allowedParams.includes(key)) {
      filteredParams.append(key, value);
    }
  }
  const mappedValues = mapQuery(query);
  if (!isEmpty(mappedValues)) {
    props.setSignupByReferral(mappedValues);
    if (mappedValues.first_name) {
      props.preSignupUserSuccess(true);
      props.updateAgentNameInSignUpProcess({
        producer_firstname: mappedValues.first_name,
        producer_lastname: mappedValues.last_name,
      });
    }
    if (mappedValues.email && !email_regex.test(mappedValues.email)) {
      mappedValues.email = '';
      setTimeout(() => displayErrorMessage(INVALID_EMAIL_NOTIFICATION), 1000);
    }
  }
  props.setInitUrl('');
  props.history?.push(`/post-signup?${filteredParams}`);
};

export const generatePossibleCombinations = (possibleValues) => {
  let arr = [];
  Object.keys(possibleValues).forEach((key) => {
    if (arr.length === 0) {
      possibleValues[key].forEach((value) => {
        arr.push({ [key]: value });
      });
    } else {
      let duplicationCount = possibleValues[key].length;
      let originalArray = cloneDeep(arr);
      let newArr = [];
      // let index = 0;
      for (let i = 0; i < originalArray.length; i++) {
        for (let j = 0; j < duplicationCount; j++) {
          newArr.push(
            Object.assign(cloneDeep(originalArray[i]), {
              [key]: possibleValues[key][j],
            })
          );
        }
        // index += duplicationCount;
      }
      arr = newArr;
    }
  });
  return arr;
};

export const availableToSelect = (
  key,
  currentArray,
  possibleValues,
  selected
) => {
  let possibleCombinations = generatePossibleCombinations(possibleValues);
  let availableValues = differenceWith(
    possibleCombinations,
    currentArray,
    isMatch
  );
  availableValues = filter(availableValues, selected);
  return uniq(availableValues.map((obj) => obj[key]));
};

export const addZero = (num) => {
  return num < 10 && num > 0 ? '0' + num : num;
};

export const formatNumberIntoDollers = (
  amount,
  decimal = '.',
  thousands = ','
) => {
  if (amount === null) {
    return 'N/A';
  } else {
    let decimalCount = Number(amount) % 1 === 0 ? 0 : 2;
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 0 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;
    return (
      negativeSign +
      (negativeSign !== '-' ? '$' : '($') +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '') +
      (negativeSign !== '-' ? '' : ')')
    );
  }
};

export const showIncentiveDashboardBanner = (days) => {
  return days !== null && days >= -30;
};

export function useRankSuffix(rank) {
  if (rank === 11 || rank === 12 || rank === 13) {
    return 'th';
  }

  const endsWith = rank % 10;
  switch (endsWith) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

export const shouldRedirectToCarrierStore = (
  carrierStoreAgentApplicationRestrictionEnabled,
  carrierAppointmentRequestCount,
  isEnabledMenuBar,
  isNewCarrierStoreEnabled
) => {
  return (
    carrierStoreAgentApplicationRestrictionEnabled &&
    !isEnabledMenuBar &&
    (!carrierAppointmentRequestCount || carrierAppointmentRequestCount <= 3) &&
    !isNewCarrierStoreEnabled
  );
};
