import React from 'react';
import { connect } from 'react-redux';
import { startCase } from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import NewQuoteButton from '../new-quote-button';
import Menu from 'components/top-nav/menu';
import { switchLanguage, toggleCollapsedNav } from 'redux/actions/setting';
import {
  getAuthDetails,
  getModulePermission,
  isCarrierStore,
} from 'util/extra';
import Impersonate from '../impersonate';
import {
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
  INSIDE_THE_HEADER,
} from 'constants/action-types';
import {
  ACTIVE,
  ADMIN,
  DASHBOARD,
  DAYS_LEFT_FOR_TRIAL,
  PRODUCTION_ACCESS,
} from 'constants/constant';
import fcLogo from 'assets/images/FirstConnectLogoDark.svg';
import { fetchProducerQuoteOptions } from 'redux/actions/dashboard';
import { NEW_QUOTE_HEAP_ATTRIBUTE } from 'constants/heap-events';
import { userSignOut } from '../../redux/actions/auth';
import IsSSOHeaderButton from 'components/is-sso-header-button';
import ProgressBar from 'components/progress-bar';
import './header.scss';
import config from 'config';
import NotificationsBell from '../notifications-bell';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBox: false,
      searchText: '',
      mailNotification: false,
      userInfo: false,
      langSwitcher: false,
      appNotification: false,
      openBox: false,
    };
  }

  componentDidMount() {
    const { modulePermissionsData } = this.props;
    if (modulePermissionsData?.[DASHBOARD]?.[PRODUCTION_ACCESS]?.read) {
      this.props.fetchProducerQuoteOptions();
    }
  }

  componentDidUpdate(prevProps) {
    const { isImpersonate, modulePermissionsData } = this.props;
    const prevProductionAccess =
      prevProps.modulePermissionsData?.[DASHBOARD]?.[PRODUCTION_ACCESS]?.read;
    const currProductionAccess =
      modulePermissionsData?.[DASHBOARD]?.[PRODUCTION_ACCESS]?.read;
    if (
      (prevProps.isImpersonate !== isImpersonate &&
        isImpersonate &&
        currProductionAccess) ||
      (prevProductionAccess !== currProductionAccess && currProductionAccess)
    ) {
      this.props.fetchProducerQuoteOptions();
    }
  }

  isEnabledNewQuote = () => {
    const {
      modulePermissionsData,
      userStatus,
      isWholesaler,
      quoteOptions = [],
    } = this.props;
    const availableLobsLength = quoteOptions.reduce(
      (len, option) => len + (option?.LOBs?.length || 0),
      0
    );
    return (
      modulePermissionsData?.[DASHBOARD]?.[PRODUCTION_ACCESS]?.read &&
      userStatus === startCase(ACTIVE) &&
      !isWholesaler &&
      availableLobsLength > 0
    );
  };

  onToggleCollapsedNav = () => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  render() {
    const {
      drawerType,
      navigationStyle,
      horizontalNavPosition,
      userRole,
      isImpersonate,
      hasQuotingEngineAccess,
      carrierAppointmentRequestCount,
      carrierStoreAgentApplicationRestrictionEnabled,
      enableMenuItem,
      isNewCarrierStoreEnabled,
    } = this.props;
    const showIsSSOHeaderButton =
      userRole.toLowerCase() === 'admin' &&
      config.ADMIN_SSO_ENFORCEMENT_LEVEL > 0;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'd-block d-xl-none'
      : drawerType.includes(COLLAPSED_DRAWER)
      ? 'd-block'
      : 'd-none';

    const shouldShowCarrierStore = isCarrierStore(
      carrierStoreAgentApplicationRestrictionEnabled,
      carrierAppointmentRequestCount,
      enableMenuItem,
      isNewCarrierStoreEnabled
    );
    const isCarrierStoreUrl = window.location.href.includes('carrier-store');
    const shouldRenderProgressbar = shouldShowCarrierStore && isCarrierStoreUrl;

    const trialCount = JSON.parse(localStorage.getItem(DAYS_LEFT_FOR_TRIAL));
    const showUpgradeButton =
      (trialCount || localStorage.getItem(DAYS_LEFT_FOR_TRIAL) !== null) &&
      trialCount < 1;
    const getLogoHref = () => {
      if (userRole.toLowerCase() !== ADMIN)
        return shouldShowCarrierStore
          ? '/app/producer/carrier-store'
          : '/app/producer/dashboard';
      if (shouldShowCarrierStore) return '/app/admin/carrier-store';
      if (isImpersonate) return '/app/admin/dashboard';
      return '/app/admin/agent-directory/all';
    };

    return (
      <React.Fragment>
        <AppBar
          className={`app-main-header ${
            navigationStyle === HORIZONTAL_NAVIGATION &&
            horizontalNavPosition === BELOW_THE_HEADER
              ? 'app-main-header-top'
              : ''
          } ${shouldShowCarrierStore ? 'app-main-header-collapse' : ''}
          `}
        >
          <Toolbar className="app-toolbar" disableGutters={false}>
            <div className="mobile-div">
              {navigationStyle === HORIZONTAL_NAVIGATION ? (
                <div
                  className="d-block d-md-none pointer mr-3"
                  onClick={this.onToggleCollapsedNav}
                >
                  <span className="jr-menu-icon">
                    <span className="menu-icon" />
                  </span>
                </div>
              ) : (
                <IconButton
                  className={`jr-menu-icon mr-3 ${drawerStyle} align-self-center`}
                  aria-label="Menu"
                  onClick={this.onToggleCollapsedNav}
                  disabled={shouldShowCarrierStore}
                >
                  <span className="menu-icon" />
                </IconButton>
              )}
              {!!userRole && (
                <>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      width: '30%',
                    }}
                  >
                    <Link
                      className="app-logo mr-2 d-sm-block"
                      disabled={shouldShowCarrierStore}
                      to={getLogoHref()}
                    >
                      <img
                        src={fcLogo}
                        alt="First Connect"
                        title="First Connect"
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                  <div className="quote-btn-wrap flex-class">
                    {shouldRenderProgressbar && (
                      <div
                        className="progress-bar-wrap"
                        style={{
                          right:
                            !this.props.isCrossSellExperience &&
                            this.isEnabledNewQuote() &&
                            isImpersonate
                              ? '30%'
                              : !this.props.isCrossSellExperience &&
                                this.isEnabledNewQuote()
                              ? '21%'
                              : isImpersonate
                              ? '28%'
                              : '15%',
                        }}
                      >
                        <ProgressBar />
                      </div>
                    )}
                    {userRole.toLowerCase() !== 'admin' &&
                      userRole.toLowerCase() !== 'super-admin' && (
                        <NotificationsBell />
                      )}

                    {!this.props.isCrossSellExperience &&
                      !shouldShowCarrierStore &&
                      this.isEnabledNewQuote() && (
                        <div
                          className={`header__new-quote-button-wrapper ${
                            isImpersonate &&
                            'header__new-quote-button-wrapper--impersonate'
                          } ${
                            hasQuotingEngineAccess &&
                            !showUpgradeButton &&
                            'header__new-quote-button-wrapper--show'
                          }`}
                        >
                          {hasQuotingEngineAccess && !showUpgradeButton && (
                            <NewQuoteButton
                              from={NEW_QUOTE_HEAP_ATTRIBUTE.TOP}
                            />
                          )}
                        </div>
                      )}
                    {showIsSSOHeaderButton && <IsSSOHeaderButton />}
                    <IconButton
                      disableRipple
                      className={`sign-out-btn ${
                        isImpersonate ? 'right-20' : 'right-4'
                      }`}
                      onClick={this.props.userSignOut}
                    >
                      <span className="icon-Logout"></span>
                      <span className="lbl-signout">Sign Out</span>
                    </IconButton>
                  </div>
                </>
              )}
            </div>
            {isImpersonate && (
              <div className="w-100">
                <Impersonate />
              </div>
            )}
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === INSIDE_THE_HEADER && <Menu />}
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  settings,
  auth,
  myAccount,
  dashboard,
  impersonate,
  modulePermissions,
  router,
  producerSettings,
}) => {
  const { isNewCarrierStoreEnabled } = producerSettings;
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  const { details } = myAccount;
  const { quoteOptions } = dashboard;
  const { producer, isImpersonate, permissions } = impersonate;
  const {
    isCrossSellExperience,
    carrierAppointmentRequestCount,
    carrierStoreAgentApplicationRestrictionEnabled,
    enableMenuItem,
  } = auth;
  const { location } = router;
  const {
    userRole,
    producer_code,
    isWholesaler,
    userStatus,
    hasQuotingEngineAccess,
  } = getAuthDetails({
    auth,
    producer,
    isImpersonate,
  });
  let modulePermissionsData = getModulePermission(
    modulePermissions.modulePermissionsData,
    permissions,
    isImpersonate
  );
  return {
    drawerType,
    navigationStyle,
    horizontalNavPosition,
    userStatus,
    userRole,
    producer_code,
    details,
    isImpersonate,
    quoteOptions,
    modulePermissionsData,
    isWholesaler,
    isCrossSellExperience,
    hasQuotingEngineAccess,
    location,
    carrierAppointmentRequestCount,
    carrierStoreAgentApplicationRestrictionEnabled,
    enableMenuItem,
    isNewCarrierStoreEnabled,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    toggleCollapsedNav,
    switchLanguage,
    fetchProducerQuoteOptions,
    userSignOut,
  })(Header)
);
