import React from 'react';

const IncentivesIcon = () => (
  <svg viewBox="0 0 22 18">
    <path
      d="M12.0049 13.941V16.0029H17.0049V18.0029H5.00488V16.0029H10.0049V13.941C6.05857 13.4489 3.00488 10.0825 3.00488 6.00293V0.00292969H19.0049V6.00293C19.0049 10.0825 15.9512 13.4489 12.0049 13.941ZM5.00488 2.00293V6.00293C5.00488 9.3166 7.69117 12.0029 11.0049 12.0029C14.3186 12.0029 17.0049 9.3166 17.0049 6.00293V2.00293H5.00488ZM0.00488281 2.00293H2.00488V6.00293H0.00488281V2.00293ZM20.0049 2.00293H22.0049V6.00293H20.0049V2.00293Z"
      fill="currentColor"
    />
  </svg>
);

const ReferralProgramIcon = () => (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      d="M18.3643 15.5353L16.95 14.1211L18.3643 12.7069C20.3169 10.7543 20.3169 7.58847 18.3643 5.63585C16.4116 3.68323 13.2458 3.68323 11.2932 5.63585L9.87898 7.05007L8.46477 5.63585L9.87898 4.22164C12.6127 1.48797 17.0448 1.48797 19.7785 4.22164C22.5121 6.95531 22.5121 11.3875 19.7785 14.1211L18.3643 15.5353ZM15.5358 18.3638L14.1216 19.778C11.388 22.5117 6.9558 22.5117 4.22213 19.778C1.48846 17.0443 1.48846 12.6122 4.22213 9.87849L5.63634 8.46428L7.05055 9.87849L5.63634 11.2927C3.68372 13.2453 3.68372 16.4112 5.63634 18.3638C7.58896 20.3164 10.7548 20.3164 12.7074 18.3638L14.1216 16.9496L15.5358 18.3638ZM14.8287 7.75717L16.2429 9.17139L9.17187 16.2425L7.75766 14.8282L14.8287 7.75717Z"
      fill="currentColor"
    />
  </svg>
);

const RewardsProgramIcon = () => (
  <svg viewBox="0 0 24 24">
    <path
      d="M15.0049 2.00281C17.214 2.00281 19.0049 3.79367 19.0049 6.00281C19.0049 6.73184 18.8098 7.41532 18.4691 8.00392L23.0049 8.00281V10.0028H21.0049V20.0028C21.0049 20.5551 20.5572 21.0028 20.0049 21.0028H4.00488C3.4526 21.0028 3.00488 20.5551 3.00488 20.0028V10.0028H1.00488V8.00281L5.54065 8.00392C5.19992 7.41532 5.00488 6.73184 5.00488 6.00281C5.00488 3.79367 6.79574 2.00281 9.00488 2.00281C10.2001 2.00281 11.2729 2.52702 12.0058 3.35807C12.7369 2.52702 13.8097 2.00281 15.0049 2.00281ZM11.0049 10.0028H5.00488V19.0028H11.0049V10.0028ZM19.0049 10.0028H13.0049V19.0028H19.0049V10.0028ZM9.00488 4.00281C7.90031 4.00281 7.00488 4.89824 7.00488 6.00281C7.00488 7.05717 7.82076 7.92097 8.85562 7.99732L9.00488 8.00281H11.0049V6.00281C11.0049 5.00116 10.2686 4.1715 9.30766 4.02558L9.15415 4.00829L9.00488 4.00281ZM15.0049 4.00281C13.9505 4.00281 13.0867 4.81869 13.0104 5.85355L13.0049 6.00281V8.00281H15.0049C16.0592 8.00281 16.923 7.18693 16.9994 6.15207L17.0049 6.00281C17.0049 4.89824 16.1095 4.00281 15.0049 4.00281Z"
      fill="currentColor"
    />
  </svg>
);

const CarrierIncentivesIcon = () => (
  <svg viewBox="0 0 24 24">
    <path
      d="M17.0047 16.0029H19.0047V4.00293H9.00468V6.00293H17.0047V16.0029ZM17.0047 18.0029V21.0021C17.0047 21.5548 16.5547 22.0029 15.9978 22.0029H4.01154C3.45548 22.0029 3.00488 21.5583 3.00488 21.0021L3.00748 7.0038C3.00759 6.45103 3.45752 6.00293 4.0143 6.00293H7.00468V3.00293C7.00468 2.45064 7.4524 2.00293 8.00468 2.00293H20.0047C20.557 2.00293 21.0047 2.45064 21.0047 3.00293V17.0029C21.0047 17.5552 20.557 18.0029 20.0047 18.0029H17.0047ZM5.0073 8.00293L5.00507 20.0029H15.0047V8.00293H5.0073ZM7.00468 16.0029H11.5047C11.7808 16.0029 12.0047 15.7791 12.0047 15.5029C12.0047 15.2268 11.7808 15.0029 11.5047 15.0029H8.50468C7.12397 15.0029 6.00468 13.8837 6.00468 12.5029C6.00468 11.1222 7.12397 10.0029 8.50468 10.0029H9.00468V9.00293H11.0047V10.0029H13.0047V12.0029H8.50468C8.22854 12.0029 8.00468 12.2268 8.00468 12.5029C8.00468 12.7791 8.22854 13.0029 8.50468 13.0029H11.5047C12.8854 13.0029 14.0047 14.1222 14.0047 15.5029C14.0047 16.8837 12.8854 18.0029 11.5047 18.0029H11.0047V19.0029H9.00468V18.0029H7.00468V16.0029Z"
      fill="currentColor"
    />
  </svg>
);

export default {
  IncentivesIcon,
  ReferralProgramIcon,
  RewardsProgramIcon,
  CarrierIncentivesIcon,
};
