import * as types from '../../constants/action-types';

const initState = () => ({
  isReferralProgramEnabled: null,
  personalizedRecommendationsFlowCompleted: null,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.SET_IS_REFERRAL_PROGRAM_ENABLED: {
      return {
        ...state,
        isReferralProgramEnabled: action.payload,
      };
    }
    case types.SET_IS_PERSONALIZED_RECOMMENDATIONS_FLOW_COMPLETED: {
      return {
        ...state,
        personalizedRecommendationsFlowCompleted: action.payload,
      };
    }
    default:
      return state;
  }
};
