import { useSelector } from 'react-redux';
import {
  NEW_APP_ROUTES,
  PRINCIPAL_ACCESS_ROLE,
  PRODUCER,
} from '../../constants/constant';
import SecondaryNavigationIcons from './secondary-navigation-icons';
import { getAuthDetails } from '../../util/extra';

export const CASH_CONNECT_MODULE = 'CASH-CONNECT';
export const HELP_CENTER_MODULE = 'HELP-CENTER';
export const APPETITE_GUIDE_MODULE = 'APPETITE-GUIDE';
export const AGENT_TOOLS_MODULE = 'AGENT-TOOLS';

export const MODULES_WITH_NEW_LABEL = [];

// Some modules are implemented in the new app and their links need to be handled differently
export const NEW_APP_MODULES = [
  CASH_CONNECT_MODULE,
  APPETITE_GUIDE_MODULE,
  AGENT_TOOLS_MODULE,
];

function useIsReferralProgramPermitted() {
  const { isReferralProgramEnabled } = useSelector(
    (state) => state.producerFlags
  );
  return Boolean(isReferralProgramEnabled);
}

function useIsRewardsProgramPermitted() {
  const { producer, isImpersonate } = useSelector((state) => state.impersonate);
  const auth = useSelector((state) => state.auth);
  const { role } = getAuthDetails({
    auth,
    producer,
    isImpersonate,
  });
  const producerStatus = auth.userStatus;
  const isPrincipalAccessProducer = role === PRINCIPAL_ACCESS_ROLE;
  const isPrincipalProducer = role === PRODUCER;

  return Boolean(
    (isPrincipalProducer || isPrincipalAccessProducer) &&
      producerStatus &&
      ['Active', 'Pending', 'Verified', 'Restricted'].includes(producerStatus)
  );
}

function useIsCarrierIncentivesPermitted() {
  const producerInfo = useSelector((state) => state.auth);
  const isParticipatingInFastStart =
    producerInfo?.shouldShowFastStart && producerInfo?.daysRemaining >= 0;

  return !isParticipatingInFastStart;
}

export const CASH_CONNECT_SECONDARY_NAVIGATION_MODULES = [
  {
    title: 'Carrier Incentives',
    url: NEW_APP_ROUTES.CASH_CONNECT_CARRIER_INCENTIVES,
    IconComponent: SecondaryNavigationIcons.CarrierIncentivesIcon,
    pillText: 'New',
    getIsPermitted: useIsCarrierIncentivesPermitted,
  },
  {
    title: 'Incentives & Performance',
    url: NEW_APP_ROUTES.CASH_CONNECT_INCENTIVES,
    IconComponent: SecondaryNavigationIcons.IncentivesIcon,
  },
  {
    title: 'Referral Program',
    url: NEW_APP_ROUTES.CASH_CONNECT_REFERRAL_PROGRAM,
    IconComponent: SecondaryNavigationIcons.ReferralProgramIcon,
    pillText: 'New',
    getIsPermitted: useIsReferralProgramPermitted,
  },
  {
    title: 'Rewards Program 2025',
    url: NEW_APP_ROUTES.CASH_CONNECT_REWARDS_PROGRAM,
    IconComponent: SecondaryNavigationIcons.RewardsProgramIcon,
    pillText: 'New',
    getIsPermitted: useIsRewardsProgramPermitted,
  },
];
