// @ts-check
import { CASH_CONNECT_SECONDARY_NAVIGATION_MODULES } from './side-nav.constants';
import { navigateToNewAppRoute } from '../../util/navigate-to-new-app-route';
import { PillLabel } from '../../components/pill-label';
import React, { useEffect } from 'react';
import './secondary-navigation.scss';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { Fade } from '@material-ui/core';

/**
 * @param {object} props
 * @param {'mobile' | 'desktop'} props.mode
 * @param {() => void} props.onClose
 * @param {boolean} props.shouldDisplay
 */
export default function SecondaryNavigation({ mode, onClose, shouldDisplay }) {
  // @TODO: make it dynamic based on primary module
  const secondaryNavigationTitle = 'Cash Connect';
  const secondaryNavigationModules =
    CASH_CONNECT_SECONDARY_NAVIGATION_MODULES.filter((module) =>
      module.getIsPermitted ? module.getIsPermitted() : true
    );

  useEffect(function cleanUp() {
    return () => {
      onClose();
    };
  }, []);

  return (
    <Fade in={shouldDisplay} unmountOnExit mountOnEnter>
      <ul
        id="secondary-navigation"
        className={`secondary-navigation--${mode} nav-menu`}
        onMouseLeave={() => {
          if (mode === 'desktop') {
            onClose();
          }
        }}
      >
        <div className="secondary-navigation__title-container">
          {mode === 'mobile' && (
            <button
              className="secondary-navigation__close-button"
              onClick={onClose}
            >
              <KeyboardArrowLeft />
            </button>
          )}
          <span>{secondaryNavigationTitle}</span>
        </div>
        {secondaryNavigationModules.map((submodule) => (
          <Submodule key={submodule.url} submodule={submodule} mode={mode} />
        ))}
      </ul>
    </Fade>
  );
}

function Submodule({ submodule, mode }) {
  return (
    <li className="menu no-arrow">
      <a
        href={submodule.url}
        className="secondary-navigation__link"
        onClick={(e) => {
          e.preventDefault();
          navigateToNewAppRoute(submodule.url);
        }}
      >
        {<submodule.IconComponent />}
        <span className="nav-text">{submodule.title}</span>
        {submodule.pillText && mode === 'desktop' && (
          <PillLabel styles={{ marginLeft: 'auto' }}>
            {submodule.pillText}
          </PillLabel>
        )}
      </a>
    </li>
  );
}
