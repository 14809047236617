// @ts-check

import React from 'react';
import { useFsFlag } from '@flagship.io/react-sdk';
import { Typography } from '@material-ui/core';
import { AB_TEST_FLAGS } from 'constants/feature-flags';

function SignUpHeaderTitle() {
  const headerTitleFlag = useFsFlag(
    AB_TEST_FLAGS.SIGN_UP_HEADER_TITLE_TEXT
  );
  const defaultTitle = 'Get Started for Free';
  const title = headerTitleFlag.getValue(defaultTitle);

  return <Typography className="heading-text">{title}</Typography>;
}

export default SignUpHeaderTitle;
